import {Associate} from '@/domain/model/associate';
import {DataSet} from '@/data/repository';
import {AssociateMapper} from '@/data/mappers/associateMapper';
import constants from '@/common/constants';
import {
  deleteDoc,
  Firestore,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  Unsubscribe,
  updateDoc,
  where
} from 'firebase/firestore';
import {inject, injectable, singleton} from 'tsyringe';
import {businessAssociate, user, users} from '@/data/firebase';
import deleteProperty = Reflect.deleteProperty;

export interface BusinessItem {
  id: string
  name: string
}

export interface AssociatesRepository {
  clear()

  findById(userId: string): Promise<Associate | null | undefined>

  findAll(businessId: string, filter?: any): Promise<Associate[]>

  findAllAsync(businessId: string, callback: DataSet<Associate>): () => void

  save(item: Associate): Promise<Associate>

  delete(item: Associate)

  exists(item: Associate): Promise<boolean>

  update(item: Associate): Promise<Associate>;
}

@injectable()
@singleton()
export class AssociatesDataRepository implements AssociatesRepository {

  private readonly db: Firestore // todo: might be redundant
  private unsubscribe: Unsubscribe | null = null;
  private associates: Associate[] = [];

  constructor(@inject('FirebaseFirestore') db: Firestore) {
    this.db = db
  }

  public async delete(item: Associate) {
    return await deleteDoc(user(item.id!));
  }

  public async save(item: Associate): Promise<Associate> {
    const data = item.data();
    await setDoc(businessAssociate(item.business!.id!, item.id!), data)
    await setDoc(user(item.id!), data)
    return item;
  }

  public async exists(item: Associate): Promise<boolean> {
    const userDoc = await getDoc(user(item.id!))
    const associateDoc = await getDoc(businessAssociate(item.business!.id!, item.id!))
    return userDoc.exists() || associateDoc.exists();
  }

  public async findById(userId: string): Promise<Associate | null | undefined> {
    if (!!this.associates.length) {
      return this.associates.find((item) => item.id === userId);
    }
    const snapshot = await getDoc(user(userId))
    const data = snapshot.data();
    if (!data) {
      return null
    }
    const associate = AssociateMapper.map(data);
    associate.id = userId
    return associate;
  }

  public async findAll(businessId: string, filter?: any): Promise<Associate[]> {
    if (this.associates.length > 0) {
      return this.associates;
    }
    const querySnapshot = await getDocs(
      query(users,
        where('business.id', '==', businessId),
        where('type', '==', constants.TYPE_ASSOCIATE),
        orderBy('fullName'))
    )
    this.associates = querySnapshot.docs.map<Associate>((snapshot) => {
      const associate = AssociateMapper.map(snapshot.data());
      associate.id = snapshot.id;
      return associate;
    });
    return this.associates;
  }

  public findAllAsync(businessId: string, callback: DataSet<Associate>): () => void {
    this.associates = [];
    const usersQuery = query(users,
      where('business.id', '==', businessId),
      where('type', '==', constants.TYPE_ASSOCIATE),
      orderBy('fullName'))
    return onSnapshot(usersQuery, (querySnapshot) => {
      querySnapshot.docChanges().forEach((docChange) => {
        const snapshot = docChange.doc;
        const associate = AssociateMapper.map(snapshot.data());
        associate.id = snapshot.id;
        switch (docChange.type) {
          case 'added':
            if (this.associates.findIndex((item) => item.id === associate.id) === -1) {
              this.associates.push(associate);
            }
            break;
          case 'modified':
            this.associates.splice(this.associates.findIndex((item) => item.id === associate.id), 1, associate);
            break;
          case 'removed':
            this.associates.splice(this.associates.findIndex((item) => item.id === associate.id), 1);
            break;
          default:
        }
      });
      callback.onChanged(this.associates);
    }, (error) => {
      callback.onError(error.message)
    })
  }

  public clear() {
    if (!!this.unsubscribe) {
      this.unsubscribe();
    }
  }

  public async update(item: Associate): Promise<Associate> {
    const id = item.id!;
    deleteProperty(item, 'id');
    await updateDoc(user(id), item.data())
    item.id = id
    if (!!this.associates.length) {
      const index = this.associates.findIndex((associate) => associate.id === id);
      this.associates.splice(index, 1, item)
    }
    return item
  }
}
