import {ActionTree} from 'vuex';
import {AuthState, RootState} from '@/domain/model/types';
import axios from '@/plugins/axios';
import router from '@/router';
import {auth} from '@/plugins/firebase.init';
import {
  confirmPasswordReset,
  getAdditionalUserInfo,
  getIdTokenResult,
  GoogleAuthProvider,
  signInWithPopup,
  verifyPasswordResetCode
} from 'firebase/auth'

export const actions: ActionTree<AuthState, RootState> = {
  async searchCompanies({commit, rootGetters}, query) {
    query = query.trim();
    commit('companiesLoading');
    try {
      const axiosResponse = await axios.get('/businesses', {
        params: {searchtype: 'ts', query},
        responseType: 'json',
        validateStatus: (status) => status === 302
      });
      commit('companiesLoaded', axiosResponse.data.data.Results);
    } catch (err: any) {
      commit('companiesError', err.message);
    }
  },
  async companyDetails({commit}, companyId) {
    commit('detailsLoading');
    try {
      const axiosResponse = await axios.get(`/businesses/${companyId}`, {
        params: {type: 'ext'},
        responseType: 'json',
        validateStatus: (status) => status === 302
      });
      if (axiosResponse.status !== 302) {
        commit('detailsError', axiosResponse.statusText);
        return;
      }
      commit('detailsLoaded', axiosResponse.data.data);
    } catch (err: any) {
      commit('detailsError', err.message);
    }
  },
  async sendVerificationLink({commit}, {companyId, companyEmail, company}) {
    // commit('detailsLoading');
    try {
      const axiosResponse = await axios.post(`/verifications`, {companyId, companyEmail, company}, {
        responseType: 'json'
      });
      if (axiosResponse.status !== 200) {
        // commit('detailsError', axiosResponse.statusText);
        console.log(axiosResponse.statusText);
        return;
      }
      console.log(axiosResponse.data);
      // commit('detailsLoaded', axiosResponse.data.data);
    } catch (err) {
      // commit('detailsError', err.message);
      console.log(err);
    }
  },
  async completeCompanyVerification({commit}, {docId, skey}) {
    // commit('detailsLoading');
    try {
      const axiosResponse = await axios.get(`/verifications/${docId}`, {
        params: {skey},
        responseType: 'json',
        validateStatus: (status) => status === 200
      });
      console.log(axiosResponse.data);
      // commit('detailsLoaded', axiosResponse.data.data);
    } catch (err) {
      // commit('detailsError', err.message);
      console.log(err);
    }
  },
  async sendEmailVerificationLink({commit}, email) {
    commit('getStartedLoading');
    try {
      const axiosResponse = await axios.post(`/verifications`, {email}, {
        responseType: 'json',
        validateStatus: (status) => true
      });
      const data = axiosResponse.data;
      if (axiosResponse.status !== 200) {
        commit('getStartedError', data.message);
        return null;
      }
      commit('getStartedLoaded');
      return data.verificationId;
    } catch (err) {
      commit('getStartedError', err);
    }
    return null;
  },
  async checkEmailVerification({commit}, {docId, skey}) {
    commit('verificationLoading');
    try {
      const axiosResponse = await axios.get(`/verifications/${docId}`, {
        params: {skey},
        responseType: 'json',
        validateStatus: (status) => status === 200 || status === 400 || status === 404 || status === 412,
      });
      if (axiosResponse.status !== 200) {
        commit('verificationError', axiosResponse.data.message);
        return false
      }
      window.localStorage.setItem('registrationEmail', axiosResponse.data.email);
      commit('verificationLoaded')
      return true;
    } catch (err: any) {
      commit('verificationError', err.message);
    }
    return false;
  },
  async completeEmailVerification({commit}, {docId, skey}) {
    commit('verificationLoading');
    try {
      const axiosResponse = await axios.delete(`/verifications/${docId}`, {
        params: {skey},
        responseType: 'json',
        validateStatus: (status) => status === 200 || status === 404,
      });
      if (axiosResponse.status === 404) {
        commit('verificationError', 'Link is invalid or expired');
        return false
      }
      commit('verificationLoaded', axiosResponse.data.email);
      return true;
    } catch (err: any) {
      commit('verificationError', err.response.data.message);
    }
    return false;
  },
  async completeRegistration({commit, getters}, {fullName, businessName, password, uid}) {
    commit('loading');
    const email: string = window.localStorage.getItem('registrationEmail') || ''
    try {
      await axios.post('/business/sign-up', {fullName, businessName, password, email, uid}, {
        responseType: 'json',
        validateStatus: (status) => status === 200 || status === 201,
      });
      commit('loaded');
      return true;
    } catch (err: any) {
      commit('error', err.message);
    }
    return false;
  },
  async continueWithGoogle({commit, dispatch}) {
    commit('loading');
    const provider = new GoogleAuthProvider();
    const result = await signInWithPopup(auth, provider);
    const user = result.user;
    if (!!getAdditionalUserInfo(result)?.isNewUser) {
      const ok = await dispatch('completeRegistration', {uid: user!.uid});
      if (!ok) {
        return await user!.delete();
      }
      await user!.reload();
    }
    const tokenResult = await getIdTokenResult(user, true);
    if (tokenResult.claims.associate) {
      await router.push({name: 'get-started'});
    } else {
      await dispatch('signOutCurrent', null, {root: true});
    }
    commit('loaded');
  },
  async requestPasswordReset({commit}, email) {
    commit('loading');
    try {
      const axiosResponse = await axios.post('/AuthService.ResetPassword', {
        email,
        userType: 1
      }, {responseType: 'json'});
      if (axiosResponse.status !== 200) {
        commit('error', axiosResponse.statusText);
        return false;
      }
      commit('loaded');
      return true;
    } catch (err: any) {
      commit('error', err.message);
    }
    return false;
  },
  async verifyPasswordResetCode({commit}, oobCode) {
    commit('loading');
    try {
      const email = await verifyPasswordResetCode(auth, oobCode as string);
      commit('loaded');
      return !!email;
    } catch (err: any) {
      commit('error', err.message);
    }
    return false;
  },
  async resetPassword({commit}, {oobCode, password}) {
    commit('loading');
    try {
      await confirmPasswordReset(auth, oobCode, password);
      commit('loaded');
      return true;
    } catch (err: any) {
      commit('error', err.message);
    }
    return false;
  },
};
