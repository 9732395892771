import {ConversationsState, MessageUI} from '@/domain/model/types';
import {ActionTree, GetterTree, MutationTree} from 'vuex';
import {firestore, storage} from '@/plugins/firebase.init';
import {
  addDoc,
  arrayRemove,
  deleteDoc,
  GeoPoint,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  Timestamp,
  updateDoc,
  where,
  writeBatch
} from 'firebase/firestore';
import constants from '@/common/constants';
import axios from '@/plugins/axios';
import {container} from 'tsyringe';
import {UnblockUserRequest, UnblockUserUseCase} from '@/domain/unblockUserUseCase';
import {BlockUserRequest, BlockUserUseCase} from '@/domain/blockUserUseCase';
import rfdc from 'rfdc';
import {chat, chatMessage, chatMessages, newChatMessage, functions} from '@/data/firebase';
import {getDownloadURL, ref, uploadBytes} from 'firebase/storage';
import {formatSectionDate, getMobileOS} from '@/utils/helpers';
import {httpsCallable} from "firebase/functions";

const env: any = process.env;
const clone = rfdc({proto: true})
const blockUser = container.resolve<BlockUserUseCase>('BlockUser')
const unblockUser = container.resolve<UnblockUserUseCase>('UnblockUser')

var lastDate = {};
var show_modified_timestamp:Boolean = false

const defaultState: ConversationsState = {
  cachedMessages: {},
  cachedLastTextSessions: {},
  caseStatus: {
    CASE_REQUESTED: 1,
    CASE_ACCEPTED: 2,
    CASE_REJECTED: 3,
    CASE_CLOSED: 4
  },
  messageType: {
    STANDARD: 1,
    FORWARD: 10,
    BUSINESS_CARD: 12,
    REQUEST_PERSONAL_DATA: 13,
    IMAGE: 14,
    ADD_PERSON_TO_GROUP: 15,
    REPLY_TO: 18,
    PERSONA_DATA_RESULT: 19,
    LOCATION: 20,
    SYSTEM: 22,
    REQUEST_APPOINTMENT: 25,
    ATTACHMENT: 30
  },
  selectedLocation: {lat: 0, lng: 0},
  selectedLocationAddress: null,
  selectedChatId: null,
  selectedTextSession: null,
  messages: [],
  messagesLoaded: false,
  fullConversation: false,
  loadingMessages: false,
  seenByMessages: []
};

const state: ConversationsState = Object.assign({}, defaultState);

const getters: GetterTree<ConversationsState, any> = {
  selectedLocation: (state) => state.selectedLocation,
  selectedLocationAddress: (state) => state.selectedLocationAddress,
  selectedChatId: (state) => state.selectedChatId,
  selectedTextSession: (state) => state.selectedTextSession,
  messages: (state) => state.messages,
  isActiveChat: (state) => !!state.selectedTextSession && state.selectedTextSession.type === constants.TEXT_SESSION_CONVERSATION,
  isGroupChat: (state) => !!state.selectedTextSession && state.selectedTextSession.subtype !== constants.TEXT_SESSION_SUBTYPE_P2P,
  messageType: (state) => state.messageType,
  messagesLoaded: (state) => state.messagesLoaded,
  caseStatus: (state) => state.caseStatus,
  showFullConversation: (state) => state.fullConversation,
  isLoadingMessages: (state) => state.loadingMessages,
  cachedLastTextSessions: (state) => state.cachedLastTextSessions,
  cachedMessages: (state) => state.cachedMessages
};

const mutations: MutationTree<ConversationsState> = {
  setSelectedLocation(state, location) {
    state.selectedLocation = location;
  },
  setLocationAddress(state, address) {
    state.selectedLocationAddress = address;
  },
  resetConversationsState(state) {
    Object.keys(defaultState).forEach(key => state[key] = clone(defaultState)[key])
  },
  setSelectedChatId(state, selectedChatId) {
    state.selectedChatId = selectedChatId;
  },
  setSelectedTextSession(state, textSession) {
    state.selectedTextSession = textSession;
  },
  setMessages(state, messages: MessageUI[]) {
    state.messages = messages;
  },
  setMessagesLoaded(state, value) {
    state.messagesLoaded = value;
  },
  setFullConversation(state, value) {
    state.fullConversation = value
  },
  setLoadingMessages(state, value) {
    state.loadingMessages = value
  },
  cacheLastTextSession(state, {key, value}) {
    state.cachedLastTextSessions[key] = value
  },
  cacheMessages(state, {chatId, messages}) {
    state.cachedMessages[chatId] = messages
  },
  addUpdateSeenByMessage(state, message) {
    state.seenByMessages.push(message)
  },
  clearSeenByMessages(state) {
    state.seenByMessages = []
  }
};

function computeKey(index: number, messages: any[], currentUid, userId: string): [string, boolean] {
  const prevIndex = index - 1
  const nextIndex = index + 1

  const prevMsg = messages[prevIndex]
  const nextMsg = messages[nextIndex]

  const prevUid = prevMsg?.data?.sender?.uid;
  const nextUid = nextMsg?.data?.sender?.uid;
  const prevSenderType = prevMsg?.data?.sender?.type;
  const nextSenderType = nextMsg?.data?.sender?.type;
  const prevMsgType = prevMsg?.data?.type;
  const nextMsgType = nextMsg?.data?.type;

  const meBefore: boolean | undefined = currentUid === prevUid && prevSenderType !== constants.SENDER_TYPE_SYSTEM &&
    !constants.SYSTEM_MESSAGES.includes(prevMsgType)
  const meAfter: boolean | undefined = currentUid === nextUid && nextSenderType !== constants.SENDER_TYPE_SYSTEM &&
    !constants.SYSTEM_MESSAGES.includes(nextMsgType)

  if (!meBefore && !meAfter) {
    return ['base', meBefore]
  }
  const prefix = userId === currentUid ? 'r' : 'l'
  if (!meBefore && meAfter) {
    return [`${prefix}_sm`, meBefore]
  }
  if (meBefore && !meAfter) {
    return [`${prefix}_em`, meBefore]
  }
  return [`${prefix}_m`, meBefore];
}

const actions: ActionTree<ConversationsState, any> = {
  locationSelected({commit}, value) {
    commit('setSelectedLocation', value);
  },
  locationAddress({commit}, address) {
    commit('setLocationAddress', address);
  },
  resetSelectedLocation({commit}) {
    commit('setSelectedLocation', defaultState.selectedLocation);
    commit('setLocationAddress', defaultState.selectedLocationAddress);
  },

  // tslint:disable: no-shadowed-variable
  async rejectRequest({getters, rootGetters, dispatch}, {cId, bId}) {
    const userId = rootGetters.t2bUser && rootGetters.t2bUser.id;
    if (!userId) {
      return null;
    }
    try {
      const currentUser = rootGetters.currentUser;
      const token = currentUser ? await currentUser.getIdToken(false) : '';
      const axiosResponse = await axios.patch(`/businesses/${bId}/cases/${cId}/reject`, null, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (axiosResponse.status !== 200) {
        const message = axiosResponse.data.message;
        console.error(message)
        return message
      }
      await dispatch('selectConversation', null);
      return null
    } catch (err: any) {
      console.error(err)
      return err.message
    }
  },

  // tslint:disable: no-shadowed-variable
  async acceptRequest({getters, rootGetters}) {
    const userId = rootGetters.t2bUser && rootGetters.t2bUser.id;
    const caze = getters.selectedTextSession.case
    if (!userId || !caze) {
      return {
        requestId: null,
        error: 'Bad request'
      };
    }
    try {
      const currentUser = rootGetters.currentUser;
      const token = currentUser ? await currentUser.getIdToken(false) : '';
      const axiosResponse = await axios.patch(`/businesses/${caze.business.id}/cases/${caze.id}/accept`, null, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (axiosResponse.status !== 200) {
        const message = axiosResponse.data.message;
        console.error(message)
        return {
          requestId: null,
          error: message
        }
      }
      return {
        requestId: axiosResponse.data.textSessionId,
        error: null
      }
    } catch (err: any) {
      console.error(err)
      return {
        requestId: null,
        error: err.message
      }
    }
  },
  async forwardCase({commit, rootGetters}, {forwardedCase, toContactId}) {
    const userId = rootGetters.t2bUser && rootGetters.t2bUser.id;
    if (!userId) {
      return null;
    }
    try {
      const currentUser = rootGetters.currentUser;
      const token = currentUser ? await currentUser.getIdToken(false) : '';
      const axiosResponse = await axios.patch(`/businesses/${forwardedCase.business.id}/cases/${forwardedCase.id}/forward`, {
        toContactId
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        validateStatus: (code) => true
      });
      if (axiosResponse.status !== 200) {
        const message = axiosResponse.data.message;
        console.error(message)
        return message
      }
      return null
    } catch (err: any) {
      console.error(err)
      return err.message
    }
  },
  async requestPersonalData({commit, rootGetters}, textSession) {
    const userId: string = rootGetters.t2bUser.id;
    if (!userId) {
      console.log('user ID is undefined')
      return
    }
    const associate: any = textSession.members[userId]
    if (!associate) {
      console.log(`no chat member for user ID:${userId}`)
      return
    }
    try {
      const docRef = await addDoc(chatMessages(textSession.id), {
        text: 'Data permissions request',
        sender: {
          name: associate.name,
          uid: associate.uid || userId,
          contactId: associate.id,
          type: 1
        },
        recipient: {
          uid: textSession.customer.id,
          name: textSession.customer.name
        },
        textSessionId: textSession.id,
        type: 13, // REQUEST_PERSONAL_DATA
        permissions: {
          contact: true,
          promote: true,
          viewEmail: true,
          viewPhone: true,
          viewAddress: true,
          viewSSN: true,
          viewDOB: true,
          business: textSession.business
        },
        memberIDs: textSession.memberIDs,
        createdDate: Timestamp.now()
      });
    } catch (err) {
      console.error(err);
    }
  },
  async selectConversation({commit, getters, dispatch}, conversation) {
    if (!conversation) {
      dispatch('closeConversation');
      return;
    }
    commit('setSelectedChatId', conversation.id);
    commit('setSelectedTextSession', conversation);
  },
  closeConversation({commit}) {
    commit('setSelectedChatId', null);
    commit('setSelectedTextSession', null);
    commit('setMessages', []);
    commit('setMessagesLoaded', false);
  },

  // tslint:disable: no-shadowed-variable
  async loadChat({commit, getters, dispatch, rootGetters}, {chatId, type}) {
    if (!chatId) {
      return null
    }
    return onSnapshot(chat(chatId), (textSession) => {
      if (textSession.exists()) {
        const chatData = {id: textSession.id, ...textSession.data()};
        commit('cacheLastTextSession', {key: type, value: chatData.id})
        dispatch('selectConversation', chatData)
      }
    })
  },

  // tslint:disable: no-shadowed-variable
  async loadMessages({commit, getters, rootGetters, dispatch}, {chatId, full, isPersonal}) {
    const userId = rootGetters.t2bUser?.id;
    if (!userId) {
      return null;
    }
    const docSnapshot = await getDoc(chat(chatId));
    if (!docSnapshot.exists()) {
      return null
    }
    const chatData: any = {id: docSnapshot.id, ...docSnapshot.data()}
    const isChatMember = chatData.memberIDs?.includes(userId)
    const openedDate = chatData.case?.openedDate;
    const openDateMs = openedDate?.toMillis();

    if (!getters.cachedMessages[chatData.id]) {
      commit('cacheMessages', {chatId: chatData.id, messages: []})
    }
    commit('setMessages', getters.cachedMessages[chatData.id])

    commit('setFullConversation', full);
    // commit('setMessages', []);
    commit('setLoadingMessages', true);
    commit('setMessagesLoaded', false);
    const messages = chatMessages(chatId);
    const personalQuery = query(messages, where('memberIDs', 'array-contains', userId));
    let messagesQuery = isPersonal ? personalQuery : (rootGetters.isAdmin ? messages : personalQuery);
    messagesQuery = query(messagesQuery, orderBy('createdDate'))
    // if (!full && chat.cases > 1) {
    //     query = query.startAfter(openedDate)
    // }

    //BAW-1011
    //if (getters.messages.length) {
      //messagesQuery = query(messagesQuery, startAfter(getters.messages[getters.messages.length - 1].data.createdDate))
    //}
    
    return onSnapshot(messagesQuery, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          const data: any = {id: change.doc.id, ...change.doc.data()};
          const createdAtMillis: number = data.createdDate.toMillis() || 0;
          const date: Date = data.createdDate.toDate()
          const dayOfMonth = date.getDate();

          const message: MessageUI = {
            id: change.doc.id,
            data,
            selected: false,
            previousCaseMessage: openDateMs === createdAtMillis,
            isSectionTimestamp: false,
            createdAtMillis,
            createdAsTimestamp: date.toLocaleString('en-US', {hour: 'numeric', minute: 'numeric', hour12: true}),
            kind: 'base',
            showName: false
          };

          switch (change.type) {
            case 'added': {
              if (getters.messages.findIndex((item) => item.id === message.id) === -1) {
                let last_date = lastDate[chatId] || 0
                if (!!dayOfMonth && last_date !== dayOfMonth) {
                  lastDate = {...lastDate,[chatId]: dayOfMonth}
                  show_modified_timestamp = true
                  message.isSectionTimestamp = true
                  message.sectionTimestamp = formatSectionDate(date)
                }else{
                  show_modified_timestamp = false
                }

                const Index = getters.messages.length
                let result = computeKey(Index, getters.messages, message.data.sender.uid, userId);
                getters.messages.push({...message, kind: result[0], showName: !result[1]});
                if (result[1]) {
                  const prevIndex = Index - 1;
                  const prevMessage = getters.messages[prevIndex];
                  result = computeKey(prevIndex, getters.messages, prevMessage.data.sender.uid, userId)
                  getters.messages.splice(prevIndex, 1, {...prevMessage, kind: result[0]})
                }
                if (isChatMember && message.data.sender.uid !== userId &&
                  (!message.data.seenBy || !message.data.seenBy[userId])) {
                  commit('addUpdateSeenByMessage', message.data)
                  if(document.visibilityState == "hidden"){
                    let audio = new Audio()
                    audio.src =  require(`../../assets/sound/chat.mp3`)
                    if (chatData.case.status == 1){
                      audio.src =  require(`../../assets/sound/request.mp3`)
                    }
                    audio.play()
                  }
                }
              }
              break;
            }
            case 'modified': {
              const modifiedIndex = getters.messages.findIndex((item) => item.id === message.id);
              if (modifiedIndex !== -1) {
                  if (show_modified_timestamp) {
                    message.isSectionTimestamp = true
                    message.sectionTimestamp = formatSectionDate(date)
                  }
                let result = computeKey(modifiedIndex, getters.messages, message.data.sender.uid, userId);
                getters.messages.splice(modifiedIndex, 1, {
                  ...message,
                  kind: result[0],
                  showName: !result[1]
                });
                if (result[1]) {
                  const prevIndex = modifiedIndex - 1;
                  const prevMessage = getters.messages[prevIndex];
                  result = computeKey(prevIndex, getters.messages, prevMessage.data.sender.uid, userId)
                  getters.messages.splice(prevIndex, 1, {...prevMessage, kind: result[0]})
                }
              }
              break;
            }
            case 'removed': {
              const removedIndex = getters.messages.findIndex((item) => item.id === message.id);
              if (removedIndex !== -1) {
                getters.messages.splice(removedIndex, 1);
                const prevIndex = removedIndex - 1;
                const prevMessage = getters.messages[prevIndex];
                let senderUid = prevMessage && prevMessage.data.sender.uid;
                if (senderUid === userId) {
                  const result = computeKey(prevIndex, getters.messages, senderUid, userId)
                  getters.messages.splice(prevIndex, 1, {...prevMessage, kind: result[0]})
                }
                const nextMessage = getters.messages[removedIndex];
                senderUid = nextMessage && nextMessage.data.sender.uid;
                if (senderUid === userId) {
                  const result = computeKey(removedIndex, getters.messages, senderUid, userId)
                  getters.messages.splice(removedIndex, 1, {...nextMessage, kind: result[0]})
                }
              }
              break;
            }
            default:
          }
        });
        commit('setMessagesLoaded', true);
        commit('setLoadingMessages', false);
        dispatch('updateSeenByMessages', chatId)
      },
      (error) => console.log(error));

  },

  // tslint:disable: no-shadowed-variable
  async updateSeenByMessages({state, commit, rootGetters}, chatId) {
    const userId = rootGetters.t2bUser?.id;
    if (!userId) {
      return;
    }
    const batch = writeBatch(firestore)
    for (const message of state.seenByMessages) {
      batch.set(chatMessage(chatId, message.id), {seenBy: {[userId]: Timestamp.now()}}, {merge: true})
    }
    await batch.commit()
    commit('clearSeenByMessages')
  },

  // tslint:disable: no-shadowed-variable
  async sendImageMessage({commit, state, getters, rootGetters}, {src, file, width, height, isSMS}) {
    const textSession = getters.selectedTextSession;
    let photoUrl = rootGetters.t2bUser.photoUrl && rootGetters.t2bUser.photoUrl.thumbnail
    let name = rootGetters.t2bUser.fullName;
    let contactId = rootGetters.t2bUser.defaultContactId || '';
    const userId = rootGetters.t2bUser.id;
    let uid = textSession.associate && textSession.associate.uid || null;
    if (userId === uid) {
      photoUrl = textSession.associate && textSession.associate.photoUrl && textSession.associate.photoUrl.thumbnail || ''
      name = textSession.associate && textSession.associate.name || '';
      contactId = textSession.associate && textSession.associate.id || '';
    } else {
      uid = userId
    }
    const messageData: any = {
      text: file.name,
      sender: {uid, contactId, name, type: 1},
      image: {
        name: file.name,
        size: {width, height},
        url: src
      },
      textSessionId: textSession.id,
      type: getters.messageType.IMAGE,
      createdDate: Timestamp.now(),
      memberIDs: textSession.memberIDs,
      isSMS: isSMS,
      platform: 'web'
    };
    if (textSession.case) {
      messageData.caseId = textSession.case.id
    }
    if (!!photoUrl) {
      messageData.photoUrl = photoUrl
    }

    const messageRef = await addDoc(chatMessages(textSession.id), messageData);
    const filePath = `/${textSession.id}/${messageRef.id}/${file.name}`;
    const fileSnapshot = await uploadBytes(ref(storage, filePath), file);
    const url = await getDownloadURL(fileSnapshot.ref);
    await updateDoc(messageRef,
      'image.url', url,
      'image.storageUri', fileSnapshot.metadata.fullPath
    );
  },

  async sendAttachmentMessage({commit, state, getters, rootGetters}, {src, file, isSMS}) {
    const textSession = getters.selectedTextSession;
    let photoUrl = rootGetters.t2bUser.photoUrl && rootGetters.t2bUser.photoUrl.thumbnail
    let name = rootGetters.t2bUser.fullName;
    let contactId = rootGetters.t2bUser.defaultContactId || '';
    const userId = rootGetters.t2bUser.id;
    let uid = textSession.associate && textSession.associate.uid || null;
    if (userId === uid) {
      photoUrl = textSession.associate && textSession.associate.photoUrl && textSession.associate.photoUrl.thumbnail || ''
      name = textSession.associate && textSession.associate.name || '';
      contactId = textSession.associate && textSession.associate.id || '';
    } else {
      uid = userId
    }
    const messageData: any = {
      text: file.name.trim(),
      sender: {uid, contactId, name, type: 1},
      attachment: {
        name: file.name,
        url: src
      },
      textSessionId: textSession.id,
      type: getters.messageType.ATTACHMENT,
      createdDate: Timestamp.now(),
      memberIDs: textSession.memberIDs,
      isSMS: isSMS,
      platform: 'web'
    };
    if (textSession.case) {
      messageData.caseId = textSession.case.id
    }
    if (!!photoUrl) {
      messageData.photoUrl = photoUrl
    }

    const messageRef = await addDoc(chatMessages(textSession.id), messageData);
    const filePath = `/${textSession.id}/${messageRef.id}/${file.name}`;
    const fileSnapshot = await uploadBytes(ref(storage, filePath), file);
    const url = await getDownloadURL(fileSnapshot.ref);
    await updateDoc(messageRef,
      'attachment.url', url,
      'attachment.storageUri', fileSnapshot.metadata.fullPath
    );
  },

  // tslint:disable: no-shadowed-variable
  async deleteMessage({getters, rootGetters}, {messageId, forAll}) {
    const messageRef = chatMessage(getters.selectedTextSession.id, messageId);
    await (forAll ? deleteDoc(messageRef) : updateDoc(messageRef, 'memberIDs', arrayRemove(rootGetters.t2bUser.id)));
  },

  // tslint:disable: no-shadowed-variable
  async deleteMessages({getters, rootGetters}, {messageIDs, forAll}) {
    const userId = rootGetters.t2bUser.id;
    const batch = writeBatch(firestore);
    for (const messageId of messageIDs) {
      const messageRef = chatMessage(getters.selectedTextSession.id, messageId);
      if (forAll) {
        batch.delete(messageRef);
      } else {
        batch.update(messageRef, 'memberIDs', arrayRemove(userId))
      }
    }
    await batch.commit();
  },

  // tslint:disable: no-shadowed-variable
  sendReplyMessage({state, commit, getters, rootGetters}, {replyTo, text}) {
    const textSession = getters.selectedTextSession;
    let photoUrl = rootGetters.t2bUser.photoUrl && rootGetters.t2bUser.photoUrl.thumbnail
    let name = rootGetters.t2bUser.fullName;
    let contactId = rootGetters.t2bUser.defaultContactId || '';
    const userId = rootGetters.t2bUser.id;
    let uid = textSession.associate && textSession.associate.uid || null;
    if (userId === uid) {
      photoUrl = textSession.associate && textSession.associate.photoUrl && textSession.associate.photoUrl.thumbnail || ''
      name = textSession.associate && textSession.associate.name || '';
      contactId = textSession.associate && textSession.associate.id || '';
    } else {
      uid = userId
    }
    const data: any = {
      repliedMessage: replyTo,
      text,
      sender: {uid, contactId, name, type: 1},
      textSessionId: textSession.id,
      type: getters.messageType.REPLY_TO,
      createdDate: Timestamp.now(),
      memberIDs: textSession.memberIDs
    };
    if (textSession.case) {
      data.caseId = textSession.case.id
    }
    if (!!photoUrl) {
      data.photoUrl = photoUrl
    }
    return addDoc(chatMessages(textSession.id), data);
  },

  // tslint:disable: no-shadowed-variable
  editMessage({state, commit, getters, rootGetters}, {messageId, text}) {
    return updateDoc(chatMessage(getters.selectedTextSession.id, messageId),
      'text', text, 'edited', true);
  },

  // tslint:disable: no-shadowed-variable
  sendTextMessage({state, commit, getters, rootGetters}, {text, isSMS}) {
    const textSession = getters.selectedTextSession;
    let photoUrl = rootGetters.t2bUser.photoUrl && rootGetters.t2bUser.photoUrl.thumbnail
    let name = rootGetters.t2bUser.fullName;
    let contactId = rootGetters.t2bUser.defaultContactId || '';
    const userId = rootGetters.t2bUser.id;
    let uid = textSession.associate && textSession.associate.uid || null;
    if (userId === uid) {
      photoUrl = textSession.associate && textSession.associate.photoUrl && textSession.associate.photoUrl.thumbnail || ''
      name = textSession.associate && textSession.associate.name || '';
      contactId = textSession.associate && textSession.associate.id || '';
    } else {
      uid = userId
    }
    const data: any = {
      text,
      sender: {uid, contactId, name, type: 1},
      textSessionId: textSession.id,
      type: getters.messageType.STANDARD,
      createdDate: Timestamp.now(),
      memberIDs: textSession.memberIDs,      
      isSMS: isSMS,
      platform: 'web'
    };
    if (textSession.case) {
      data.caseId = textSession.case.id
    }
    if (!!photoUrl) {
      data.photoUrl = photoUrl
    }
    return addDoc(chatMessages(textSession.id), data);
  },
  
  // tslint:disable: no-shadowed-variable
  async sendSMS({rootGetters}, {from, to, to_toId, isGroup, text, isFirstSMS, toId, isChannel, channelId, channelName, isMMS, imageUrl}) {    
    
    try {

      // token
      const textSession = rootGetters.selectedTextSession;
      const currentUser = rootGetters.currentUser;
      const userId = rootGetters.t2bUser.id;
      const token = currentUser ? await currentUser.getIdToken(false) : '';   
      const businessId = rootGetters.businessId || '';   
      const businessName = rootGetters.businessName || '';

      let textSessionId: any, caseId: any;
      let uid, name;

      if(isChannel){
        
        // isChannel        
        channelId = channelId || '';
        name = channelName;

      }else{
        // isChat
        textSessionId = textSession?.id || '';
        caseId = textSession?.case && textSession?.case?.id || '';

        // uid
        uid = textSession.associate && textSession.associate.uid || null;        

        // from name
        name = textSession.associate && textSession.associate.name || '';
        if(!name || name === ''){
          name = rootGetters.t2bUser.fullName;
        }
      }   
      
      if (userId !== uid) {
        uid = userId
      }

      //return null;

      // api      
      const result = await axios.get(env.VUE_APP_API_FUNCTION_URL + '/apiSendSms', {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {          
          from: from,
          to: to,
          to_toId: to_toId,
          isGroup: isGroup,
          text: text,   
          fromName: name,       
          fromId: uid,
          toId: toId,          
          isFirstSMS: isFirstSMS,          
          isOpt: isFirstSMS,
          platform: getMobileOS(),          
          textSessionId: textSessionId,
          caseId : caseId,       
          isChannel: isChannel,          
          channelId: channelId,
          businessId: businessId,
          businessName: businessName,          
          isMMS: isMMS,
          imageUrl: imageUrl
        },        
      });

      // result
      let responseBody = {};

      if (result.status === 200) {  
        if(result.data.status === 200 && result.data.errorCode === '0'){
          console.log('Success')
          responseBody = {
            errorCode: '0'
          };
        }else{
          console.error('Success with error code')
          responseBody = {
            errorCode: result.data.errorCode,
            errorText: result.data.errorText
          }; 
        }        
      }

      // reposne
      return responseBody;

    } catch (err) {   
      console.error('Error in Send SMS')  
      console.error(err)
    }
    return null;
  },

  // tslint:disable: no-shadowed-variable
  forwardMessage({state, commit, getters, rootGetters}, {messageToForward, text}) {
    const textSession = getters.selectedTextSession;
    let photoUrl = rootGetters.t2bUser.photoUrl && rootGetters.t2bUser.photoUrl.thumbnail
    let name = rootGetters.t2bUser.fullName;
    let contactId = rootGetters.t2bUser.defaultContactId || '';
    const userId = rootGetters.t2bUser.id;
    let uid = textSession.associate && textSession.associate.uid || null;
    if (userId === uid) {
      photoUrl = textSession.associate && textSession.associate.photoUrl && textSession.associate.photoUrl.thumbnail || ''
      name = textSession.associate && textSession.associate.name || '';
      contactId = textSession.associate && textSession.associate.id || '';
    } else {
      uid = userId
    }
    const batch = writeBatch(firestore);
    if (Array.isArray(messageToForward)) {
      for (const message of messageToForward) {
        const data: any = {
          forwardedMessage: message.type === getters.messageType.FORWARD ? message.forwardedMessage : message,
          text: message.text,
          sender: {uid, contactId, name, type: 1},
          textSessionId: textSession.id,
          type: getters.messageType.FORWARD,
          createdDate: Timestamp.now(),
          memberIDs: textSession.memberIDs
        };
        if (textSession.case) {
          data.caseId = textSession.case.id
        }
        if (!!photoUrl) {
          data.photoUrl = photoUrl
        }
        batch.set(newChatMessage(textSession.id), data);
      }
    } else {
      const data: any = {
        forwardedMessage: messageToForward.type === getters.messageType.FORWARD ?
          messageToForward.forwardedMessage : messageToForward,
        text: messageToForward.text,
        sender: {uid, contactId, name, type: 1},
        textSessionId: textSession.id,
        type: getters.messageType.FORWARD,
        createdDate: Timestamp.now(),
        memberIDs: textSession.memberIDs
      };
      if (textSession.case) {
        data.caseId = textSession.case.id
      }
      if (!!photoUrl) {
        data.photoUrl = photoUrl
      }
      batch.set(newChatMessage(textSession.id), data);
    }

    if (text !== null && text.length) {
      const data: any = {
        text,
        sender: {uid, contactId, name, type: 1},
        textSessionId: textSession.id,
        type: getters.messageType.STANDARD,
        createdDate: Timestamp.now(),
        memberIDs: textSession.memberIDs
      };
      if (textSession.case) {
        data.caseId = textSession.case.id
      }
      if (!!photoUrl) {
        data.photoUrl = photoUrl
      }
      batch.set(newChatMessage(textSession.id), data);
    }
    return batch.commit();
  },

  // tslint:disable: no-shadowed-variable
  async caseClose({getters, rootGetters, dispatch}) {
    const textSession = getters.selectedTextSession;
    const member = textSession.members[rootGetters.t2bUser.id]
    try {
      await updateDoc(chat(textSession.id),
        'case.status', getters.caseStatus.CASE_CLOSED,
        'case.closedBy', {
          id: member.uid, // todo: add 'contactId' field
          name: member.name
        },
        'case.closedDate', Timestamp.now());
      dispatch('closeConversation');
    } catch (err) {
      console.log(err)
      throw(err)
    }
  },

  // tslint:disable: no-shadowed-variable
  sendContact({state, commit, getters, rootGetters, dispatch}, {contact, isSMS}) {
    const textSession = getters.selectedTextSession;
    let photoUrl = rootGetters.t2bUser?.photoUrl?.thumbnail
    let name = rootGetters.t2bUser?.fullName;
    let contactId = rootGetters.t2bUser?.defaultContactId || '';
    const userId = rootGetters.t2bUser?.id;
    let uid = textSession.associate?.uid || null;
    if (userId === uid) {
      photoUrl = textSession.associate && textSession.associate.photoUrl && textSession.associate.photoUrl.thumbnail || ''
      name = textSession.associate && textSession.associate.name || '';
      contactId = textSession.associate && textSession.associate.id || '';
    } else {
      uid = userId
    }
    const data: any = {
      text: 'Business Card',
      sender: {uid, contactId, name, type: 1},
      textSessionId: textSession.id,
      type: getters.messageType.BUSINESS_CARD,
      createdDate: Timestamp.now(),
      memberIDs: textSession.memberIDs,
      associateContact: contact,
      contact,
      isSMS: isSMS,
      platform: 'web'
    };
    if (textSession.case) {
      data.caseId = textSession.case.id
    }
    if (!!photoUrl) {
      data.photoUrl = photoUrl
    }
    return addDoc(chatMessages(textSession.id), data);
  },
  async blockContact({getters, rootGetters}) {
    const textSession = getters.selectedTextSession;
    const customerId = textSession.customer.id;
    const request: BlockUserRequest = {
      customerId,
      associateId: rootGetters.t2bUser.id,
      businessId: rootGetters.t2bUser.business.id
    };
    if (await blockUser(request)) {
      return null
    } else {
      return 'Failed to block user'
    }
  },

  async blockContactasAdmin({getters, rootGetters}) {
    const textSession = getters.selectedTextSession;
    const customerId = textSession.customer.id;
    const request: BlockUserRequest = {
      customerId,
      associateId: textSession.associate.uid,
      businessId: rootGetters.t2bUser.business.id
    };
    if (await blockUser(request)) {
      return null
    } else {
      return 'Failed to block user'
    }
  },

  // tslint:disable: no-shadowed-variable
  async unblockContact({getters, rootGetters}) {
    const textSession = getters.selectedTextSession;
    const request: UnblockUserRequest = {
      customerId: textSession.customer.id,
      associateId: rootGetters.t2bUser.id,
      businessId: rootGetters.t2bUser.business.id
    };
    if (await unblockUser(request)) {
      return null
    } else {
      return 'Failed to unblock user'
    }
  },

  // tslint:disable: no-shadowed-variable
  canLeaveChat({getters}) {
    const validCount = getters.selectedTextSession.members.length > 2;
    return (getters.isGroupChat && validCount) || validCount || !getters.isActiveChat;
  },

  // tslint:disable: no-shadowed-variable
  async leaveChat({getters, rootGetters, dispatch}) {
    const userId = rootGetters.t2bUser && rootGetters.t2bUser.id;
    const chatId = getters.selectedChatId
    if (!userId || !chatId) {
      return 'Bad request';
    }
    const currentUser = rootGetters.currentUser;
    try {
      const token = currentUser ? await currentUser.getIdToken(false) : '';
      const axiosResponse = await axios.patch(`/textsessions/${chatId}`, null, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (axiosResponse.status === 200) {
        dispatch('closeConversation');
      }
      return null
    } catch (err: any) {
      console.error(err)
      return err.message;
    }
  },

  // tslint:disable: no-shadowed-variable
  sendLocationMessage({getters, rootGetters}, {isSMS}) {
    const textSession = getters.selectedTextSession;
    let photoUrl = rootGetters.t2bUser.photoUrl && rootGetters.t2bUser.photoUrl.thumbnail
    let name = rootGetters.t2bUser.fullName;
    let contactId = rootGetters.t2bUser.defaultContactId || '';
    const userId = rootGetters.t2bUser.id;
    let uid = textSession.associate && textSession.associate.uid || null;
    if (userId === uid) {
      photoUrl = textSession.associate && textSession.associate.photoUrl && textSession.associate.photoUrl.thumbnail || ''
      name = textSession.associate && textSession.associate.name || '';
      contactId = textSession.associate && textSession.associate.id || '';
    } else {
      uid = userId
    }
    const data: any = {
      text: getters.selectedLocationAddress || 'User location',
      geopoint: new GeoPoint(getters.selectedLocation.lat, getters.selectedLocation.lng),
      sender: {uid, contactId, name, type: 1},
      textSessionId: textSession.id,
      type: getters.messageType.LOCATION,
      createdDate: Timestamp.now(),
      memberIDs: textSession.memberIDs,
      isSMS: isSMS,
      platform: 'web'
    };
    if (textSession.case) {
      data.caseId = textSession.case.id
    }
    if (!!photoUrl) {
      data.photoUrl = photoUrl
    }
    return addDoc(chatMessages(textSession.id), data);
  },

  // tslint:disable: no-shadowed-variable
  requestAppointment({getters, rootGetters}) {
    const textSession = getters.selectedTextSession;
    let name = rootGetters.t2bUser.fullName;
    let contactId = rootGetters.t2bUser.defaultContactId || '';
    const userId = rootGetters.t2bUser.id;
    let uid = textSession.associate && textSession.associate.uid || null;
    if (userId === uid) {
      name = textSession.associate && textSession.associate.name || '';
      contactId = textSession.associate && textSession.associate.id || '';
    } else {
      uid = userId
    }
    const data: any = {
      text: 'Appointment Request',
      type: getters.messageType.REQUEST_APPOINTMENT,
      recipient: {
        uid: textSession.customer.uid,
        contactId: textSession.customer.uid,
        name: textSession.customer.name
      },
      sender: {
        uid,
        contactId,
        name,
        type: 2
      },
      memberIDs: textSession.memberIDs,
      textSessionId: textSession.id,
      createdDate: Timestamp.now()
    };
    if (textSession.case) {
      data.caseId = textSession.case.id
    }
    return addDoc(chatMessages(textSession.id), data);
  },

  resetMessagesLoaded({commit}) {
    commit('setMessagesLoaded', false);
  },

  clearState({commit}) {
    commit('resetConversationsState');
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
