import {randomise} from '@/utils/helpers';
import {Auth, createUserWithEmailAndPassword, updateProfile} from 'firebase/auth';

export type AddFirebaseUserUseCase = (email: string, userName?: string, password?: string) => Promise<string | null>

export const addFirebaseUserUseCase = (auth: Auth): AddFirebaseUserUseCase => {
  return async (email: string, userName?: string, password?: string): Promise<string | null> => {
    const userCredential = await createUserWithEmailAndPassword(auth, email, !!password ? password : randomise());
    const user = userCredential.user;
    await updateProfile(user, {displayName: userName});
    await auth.signOut();
    return user.uid || null;
  }
}
