import {DependencyContainer, FactoryProvider, injectable, singleton} from 'tsyringe';
import {unblockUserUseCase, UnblockUserUseCase} from '@/domain/unblockUserUseCase';

@injectable()
@singleton()
export default class UnblockUserUseCaseFactory implements FactoryProvider<UnblockUserUseCase> {
  public useFactory(dc: DependencyContainer): UnblockUserUseCase {
    return unblockUserUseCase(dc.resolve('FirebaseAuth'), dc.resolve('Axios'));
  }
}
