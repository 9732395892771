import 'reflect-metadata'
import {container} from 'tsyringe';
import {AssociatesDataRepository, AssociatesRepository} from '@/data/associatesDataRepository';
import {ChannelsDataRepository, ChannelsRepository} from '@/data/channelsDataRepository'
import {PhotoDataRepository} from '@/data/photoDataRepository';
import {InvitesDataRepository} from '@/data/invitesDataRepository';
import ContactsDataRepository from '@/data/contactsDataRepository';
import {ContactPhotoDataRepository} from '@/data/contactPhotoDataRepository';
import {AuthDataRepository} from '@/data/authDataRepository';
import {ApiDataService} from '@/data/apiService';
import {adminAuth, auth, firestore} from '@/plugins/firebase.init';
import AddFirebaseUserUseCaseFactory from '@/di/addFirebaseUserUseCaseFactory';
import ReInviteUserUseCaseFactory from '@/di/reInviteUserUseCaseFactory';
import UnblockUserUseCaseFactory from '@/di/unblockUserUseCaseFactory';
import BlockUserUseCaseFactory from '@/di/blockUserUseCaseFactory';
import axios from 'axios';
import CreateChannelUseCaseFactory from '@/di/createChannelUseCaseFactory';
import EditChannelUseCaseFactory from '@/di/editChannelUseCaseFactory';
import {ChannelsImagesDataRepository} from '@/data/channelsImagesDataRepository';
import UploadChannelImageUseCaseFactory from '@/di/uploadChannelImageUseCaseFactory';

container.registerInstance('FirebaseAuth', auth)
container.registerInstance('FirebaseAdminAuth', adminAuth)
container.registerInstance('FirebaseFirestore', firestore)
container.registerInstance('Axios', axios)

container.register('AssociatesRepository', {useClass: AssociatesDataRepository})
container.register('ChannelsRepository', {useClass: ChannelsDataRepository})
container.register('PhotoRepository', {useClass: PhotoDataRepository})
container.register('InvitesRepository', {useClass: InvitesDataRepository})
container.register('ContactsRepository', {useClass: ContactsDataRepository})
container.register('ContactPhotoRepository', {useClass: ContactPhotoDataRepository})
container.register('AuthRepository', {useClass: AuthDataRepository})
container.register('ApiService', {useClass: ApiDataService})
container.register('ChannelsImagesRepository', {useClass: ChannelsImagesDataRepository})

container.register('AddFirebaseUserUseCaseFactory', {useClass: AddFirebaseUserUseCaseFactory})
container.register('ReInviteUserUseCaseFactory', {useClass: ReInviteUserUseCaseFactory})
container.register('CreateChannelUseCaseFactory', {useClass: CreateChannelUseCaseFactory})

container.register('AddFirebaseUser', container.resolve(AddFirebaseUserUseCaseFactory))
container.register('ReInviteUser', container.resolve(ReInviteUserUseCaseFactory))
container.register('BlockUser', container.resolve(BlockUserUseCaseFactory))
container.register('UnblockUser', container.resolve(UnblockUserUseCaseFactory))
container.register('CreateChannel', container.resolve(CreateChannelUseCaseFactory))
container.register('EditChannel', container.resolve(EditChannelUseCaseFactory))
container.register('UploadChannelImage', container.resolve(UploadChannelImageUseCaseFactory))
