import {GetterTree} from 'vuex';
import {AuthState, RootState} from '@/domain/model/types';

export const getters: GetterTree<AuthState, RootState> = {
  foundCompanies: (state): any[] => state.companies,
  company: (state): any => state.companyDetails,
  error: (state): any => state.error,
  loading: (state): any => state.loading,
  email: (state): any => state.email,
  oobCode: (state): string | null => state.oobCode
};
