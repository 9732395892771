import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/dist/vuetify.min.css';
import Vue from 'vue';
import Vuetify from 'vuetify';
import theme from './theme';
import IconCheckboxOff from '@/components/icons/IconCheckboxOff.vue';
import IconCheckboxOn from '@/components/icons/IconCheckboxOn.vue';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
        values: {
            iconCheckboxOff: {
                component: IconCheckboxOff,
                props: {
                    name: 'ic-checkbox-off',
                },
            },
            iconCheckboxOn: {
                component: IconCheckboxOn,
                props: {
                    name: 'ic-checkbox-on',
                },
            },
        },
    },
    theme: {
        themes: {light: theme, dark: theme}
    }
});
