import FactoryProvider from 'tsyringe/dist/typings/providers/factory-provider';
import DependencyContainer from 'tsyringe/dist/typings/types/dependency-container';
import {AddFirebaseUserUseCase, addFirebaseUserUseCase} from '@/domain/addFirebaseUserUseCase';
import {injectable, singleton} from 'tsyringe';

@injectable()
@singleton()
export default class AddFirebaseUserUseCaseFactory implements FactoryProvider<AddFirebaseUserUseCase> {
  public useFactory(dc: DependencyContainer): AddFirebaseUserUseCase {
    return addFirebaseUserUseCase(dc.resolve('FirebaseAdminAuth'));
  }
}
