import {Auth, getIdToken} from 'firebase/auth'
import {AxiosStatic} from 'axios';

export interface UnblockUserRequest {
  customerId: string
  associateId: string
  businessId: string
}

export type UnblockUserUseCase = (request: UnblockUserRequest) => Promise<boolean>

export const unblockUserUseCase = (auth: Auth, axios: AxiosStatic): UnblockUserUseCase => {
  return async (request: UnblockUserRequest): Promise<boolean> => {
    const {associateId, businessId, customerId} = request;
    if (!customerId || !associateId || !businessId) {
      return false
    }
    try {
      const currentUser = auth.currentUser;
      const token = currentUser ? await getIdToken(currentUser, false) : '';
      const axiosResponse = await axios.post('/BusinessService.unblockCustomer',
        {customerId, associateId, businessId},
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      return axiosResponse.status === 200
    } catch (err) {
      console.error(err)
    }
    return false
  }
}
