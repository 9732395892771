export class BaseClass {
    public data(): any {
        const source = this
        Object.keys(source).forEach((key) => {
            const element = source[key];
            if (element === undefined) {
                delete source[key];
            }
            if (element instanceof BaseClass) {
                source[key] = (element as BaseClass).data();
            }
        });
        return Object.assign({}, source);
    }
}
