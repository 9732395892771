import {ApiService} from '@/domain/definitions/api';
import {inject, injectable, singleton} from 'tsyringe';
import {AuthRepository} from '@/domain/definitions/auth';
import axios from '@/plugins/axios';

@injectable()
@singleton()
export class ApiDataService implements ApiService {
  private authRepository: AuthRepository

  constructor(@inject('AuthRepository') authRepository: AuthRepository) {
    this.authRepository = authRepository
  }

  public async revokeInvite(businessId: string, inviteId: string): Promise<boolean> {
    const token = await this.authRepository.getUserToken();
    const result = await axios.delete(`/businesses/${businessId}/invites/${inviteId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
    return result.status === 200;
  }

}
