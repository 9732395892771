import {BusinessItem, PhotoUrl, Roles, Stats, Status, WorkingStatus} from '@/domain/model/types';
import {BaseClass} from '@/domain/model/base_object';

export class Associate extends BaseClass {
    public id?: string | null;
    public fullName: string;
    public email: string;
    public position: string;
    public phoneNumber?: string | null;
    public ssn?: string | null;
    public photoUrl?: PhotoUrl | null;
    public type: number | null;
    public business?: BusinessItem;

    public workingStatus?: WorkingStatus | null;
    public status?: Status | null;
    public defaultContactId?: string | null;
    public admin?: boolean | null | any;
    public superAdmin?: boolean | null | any;
    public header?: string | null;
    public inbox?: number | null;
    public stats?: Stats | null;
    public roles?: Roles | null;
    public disabled?: boolean;
    public hidden?: boolean;
    public invited?: boolean;
    public invitedAt?: Date | null;

    constructor(fullName: string, email: string, position: string, phoneNumber: string | null,
                business: BusinessItem, type: number | null, admin: boolean | any | null, superAdmin: boolean | any | null, roles: Roles | null,
                ssn?: string | null, photoUrl?: PhotoUrl | null, workingStatus?: WorkingStatus | null, status?: Status | null,
                defaultContactId?: string | null, header?: string | null, inbox?: number | null, stats?: Stats | null,
                disabled?: boolean, hidden?: boolean, invited?: boolean, invitedAt?: Date | null, id?: string | null) {
        super();
        this.id = id;
        this.fullName = fullName;
        this.email = email;
        this.position = position;
        this.phoneNumber = phoneNumber;
        this.ssn = ssn;
        this.photoUrl = photoUrl;
        this.type = type;
        this.business = business;
        this.workingStatus = workingStatus;
        this.status = status;
        this.defaultContactId = defaultContactId;
        this.admin = admin;
        this.superAdmin = superAdmin;
        this.header = header;
        this.inbox = inbox;
        this.stats = stats;
        this.roles = roles;
        this.disabled = disabled;
        this.hidden = hidden;
        this.invited = invited;
        this.invitedAt = invitedAt;
    }
}
