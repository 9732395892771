import {GetterTree} from 'vuex';
import {HomeState} from '@/store/modules/home/index';
import {RootState} from '@/domain/model/types';

export const getters: GetterTree<HomeState, RootState> = {
    fulfilled: (state) => state.fulfilled,
    todo: (state) => state.todo,
    busy: (state) => state.busy,
    error: (state) => state.error,
    step: (state) => state.step
};
