import {injectable, singleton} from 'tsyringe';
import {AuthRepository, User} from '@/domain/definitions/auth';
import {auth} from '@/plugins/firebase.init';

@injectable()
@singleton()
export class AuthDataRepository implements AuthRepository {

    public deleteUser(uid: string): Promise<void> {
        return Promise.resolve()
    }

    public findUserByEmail(email: string): Promise<User> {
        return Promise.resolve({uid: '', email: '', name: ''});
    }

    public getUserToken(): Promise<string> | undefined {
        return auth.currentUser?.getIdToken(false);
    }

}
