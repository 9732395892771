












import {Component, Vue} from 'vue-property-decorator';

@Component({name: 'ic-checkbox-off'})
export default class IconCheckboxOff extends Vue {
}
