import {appConfig} from './firebase.config';
import {initializeApp} from 'firebase/app';
import {getMessaging, isSupported as messagingSupported} from 'firebase/messaging'
import {getFirestore} from 'firebase/firestore';
import {getDatabase} from 'firebase/database';
import {getStorage} from 'firebase/storage';
import {getAuth} from 'firebase/auth';
import {getFunctions} from 'firebase/functions';
import {initializeAnalytics, isSupported as analyticSupported} from 'firebase/analytics';

export const firebaseApp = initializeApp(appConfig);

console.log('Firebase App ID: ', firebaseApp.options.appId)

const adminApp = initializeApp(appConfig, 'ADMIN');
export const adminAuth = getAuth(adminApp);

analyticSupported().then((result) => {
  console.log('Analytics supported:', result)
  if (result) {
    initializeAnalytics(firebaseApp)
  }
})

export const firestore = getFirestore(firebaseApp);
export const database = getDatabase(firebaseApp);
export const functions = getFunctions(firebaseApp);
export const storage = getStorage(firebaseApp);
export const auth = getAuth(firebaseApp);

let messaging: any
messagingSupported().then((result) => {
  console.log('Messaging supported:', result)
  if (result) {
    messaging = getMessaging(firebaseApp)
  }
})

export {messaging}
