import {DataSet} from '@/data/repository';
import {BusinessContact} from '@/domain/model/types';
import {ContactMapper} from '@/data/mappers/contactMapper';
import {injectable, singleton} from 'tsyringe';
import {businessDirectory, businessDirectoryContact} from '@/data/firebase';
import {addDoc, deleteDoc, DocumentData, getDocs, limit, orderBy, query, updateDoc} from 'firebase/firestore';

export interface ContactsRepository {
  save(item: BusinessContact): Promise<BusinessContact>

  delete(item: BusinessContact)

  exists(item: BusinessContact): Promise<boolean>

  findAll(businessId: string, filter?: any): Promise<BusinessContact[]>

  findLast(businessId: string, filter?: any): Promise<BusinessContact | null>

  findAllAsync(businessId: string, callback: DataSet<BusinessContact>): () => void

  update(item: BusinessContact): Promise<BusinessContact>;
}

@injectable()
@singleton()
export default class ContactsDataRepository implements ContactsRepository {
  private contacts: BusinessContact[] = [];

  delete(item: BusinessContact) {
    return deleteDoc(businessDirectoryContact(item.business!.id!, item.id!))
  }

  exists(item: BusinessContact): Promise<boolean> {
    return Promise.resolve(false);
  }

  async findAll(businessId: string, filter?: any): Promise<BusinessContact[]> {
    const dirQuery = query(businessDirectory(businessId), orderBy('flatIndex', 'asc'))
    const querySnapshot = await getDocs(dirQuery);
    this.contacts = querySnapshot.docs.map<BusinessContact>((snapshot) => {
      const contact = ContactMapper.map(snapshot.data());
      contact.id = snapshot.id;
      return contact;
    });
    return this.contacts;
  }

  async findLast(businessId: string, filter?: any): Promise<BusinessContact | null> {
    const dirQuery = query(businessDirectory(businessId), orderBy('flatIndex', 'desc'), limit(1))
    const querySnapshot = await getDocs(dirQuery);
    const contacts = querySnapshot.docs.map<BusinessContact>((snapshot) => {
      const contact = ContactMapper.map(snapshot.data());
      contact.id = snapshot.id;
      return contact;
    });
    return contacts.length ? contacts[0] : null;
  }

  findAllAsync(businessId: string, callback: DataSet<BusinessContact>): () => void {
    return function() {
    };
  }

  async save(item: BusinessContact): Promise<BusinessContact> {
    const documentReference = await addDoc(businessDirectory(item.business!.id!), item);
    return {id: documentReference.id, ...item};
  }

  async update(item: BusinessContact): Promise<BusinessContact> {
    const docId = item.id!;
    delete item.id;
    await updateDoc(businessDirectoryContact(item.business!.id!, docId), item as DocumentData);
    return {id: docId, ...item};
  }

}
