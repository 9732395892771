import {DependencyContainer, FactoryProvider, injectable, singleton} from 'tsyringe';
import {createChannelUseCase, CreateChannelUseCase} from '@/domain/createChannelUseCase';

@injectable()
@singleton()
export default class CreateChannelUseCaseFactory implements FactoryProvider<CreateChannelUseCase> {
  public useFactory(dc: DependencyContainer): CreateChannelUseCase {
    return createChannelUseCase(dc.resolve('ChannelsRepository'), dc.resolve('UploadChannelImage'));
  }
}
