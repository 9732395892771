import {storage} from '@/plugins/firebase.init';
import {injectable, singleton} from 'tsyringe';
import {getDownloadURL, ref, uploadBytes} from 'firebase/storage';

export interface ChannelsImagesRepository {
  save(businessId: string, channelId: string, imageFile: File): Promise<string | null>
}

@injectable()
@singleton()
export class ChannelsImagesDataRepository implements ChannelsImagesRepository {

  public async save(businessId: string, channelId: string, imageFile: File): Promise<string | null> {
    const fileRef = ref(storage, `businesses/${businessId}/channels/${channelId}/images/${imageFile.name}`);
    try {
      const uploadTaskSnapshot = await uploadBytes(fileRef, imageFile);
      return await getDownloadURL(uploadTaskSnapshot.ref);
    } catch (e) {
      console.error('failed to upload channel image', e)
    }
    return null
  }
}


