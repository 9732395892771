import {DependencyContainer, FactoryProvider, injectable, singleton} from 'tsyringe';
import {blockUserUseCase, BlockUserUseCase} from '@/domain/blockUserUseCase';

@injectable()
@singleton()
export default class BlockUserUseCaseFactory implements FactoryProvider<BlockUserUseCase> {
  public useFactory(dc: DependencyContainer): BlockUserUseCase {
    return blockUserUseCase(dc.resolve('FirebaseAuth'), dc.resolve('Axios'));
  }
}
