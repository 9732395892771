import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '@/di'
import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import './directives'
import App from '@/App.vue';
import SvgIcon from 'vue-svgicon';
import router from './router';
import store from './store';
import {auth} from './plugins/firebase.init';
import Constants from '@/plugins/constants';
import Utils from '@/plugins/utils';
import ValidationRules from '@/plugins/validations';
import MaskRules from '@/plugins/mask';
import Hotjar from 'vue-hotjar';
import VueTheMask from 'vue-the-mask'
import VueGtm from '@gtm-support/vue2-gtm'
import runtime from 'serviceworker-webpack-plugin/lib/runtime';

if ('serviceWorker' in navigator) {
  runtime.register();
}

Vue.config.productionTip = true;

Vue.use(SvgIcon, {tagName: 'svgicon'});
Vue.use(Constants)
Vue.use(Utils)
Vue.use(ValidationRules)
Vue.use(MaskRules)
Vue.use(VueTheMask)
if (process.env.VUE_APP_HOTJAR_SITE_ID) {
  Vue.use(Hotjar, {
    id: process.env.VUE_APP_HOTJAR_SITE_ID
  })
}

if (process.env.VUE_APP_GOOGLE_TAG_MANAGER) {
  Vue.use(VueGtm, {
    id: process.env.VUE_APP_GOOGLE_TAG_MANAGER,
    queryParams: {},
    defer: false,
    enabled: true,
    debug: false,
    loadScript: true,
    vueRouter: router,
    ignoredViews: [],
    trackOnNextTick: false,
  })
}
let app: Vue;

auth.onAuthStateChanged((user) => {
  if (user) {
    window.dataLayer?.push({
      event: 'User Data',
      userId: user.uid
    })
  } else {
    window.dataLayer?.push({
      userId: undefined,
      businessId: undefined
    })
  }

  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount('#app');
  }
});
