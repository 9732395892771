import {ChannelsImagesRepository} from '@/data/channelsImagesDataRepository';

export interface UploadChannelImageRequest {
  businessId: string
  channelId: string
  imageFile: File
}

export type UploadChannelImageUseCase = (request: UploadChannelImageRequest) => Promise<string | null>

export const uploadChannelImageUseCase = (imagesRepository: ChannelsImagesRepository): UploadChannelImageUseCase => {
  return async (request: UploadChannelImageRequest): Promise<string | null> => {
    const businessId = request.businessId;
    const channelId = request.channelId;
    const imageFile = request.imageFile;
    return await imagesRepository.save(businessId, channelId, imageFile);
  }
}
