const constants: any = {
    TEXT_SESSION_INNER: 3,
    TEXT_SESSION_CONVERSATION: 4,
    TEXT_SESSION_SUBTYPE_P2P: 2,
    TEXT_SESSION_SUBTYPE_P2G: 1,
    TEXT_SESSION_SUBTYPE_GEXT: 3,

    TYPE_BUSINESS_ADMIN: 0,
    TYPE_ASSOCIATE: 1,
    TYPE_CUSTOMER: 2,

    TYPE_CONTACT_PERSONAL: 1,
    TYPE_CONTACT_GROUP: 2,
    TYPE_CONTACT_NODE: 3,

    MESSAGE_TYPE_STANDARD: 1,
    MESSAGE_TYPE_FORWARD: 10,
    MESSAGE_TYPE_BUSINESS_CARD: 12,
    MESSAGE_TYPE_REQUEST_PERSONAL_DATA: 13,
    MESSAGE_TYPE_IMAGE: 14,
    MESSAGE_TYPE_ADD_PERSON_TO_GROUP: 15,
    MESSAGE_TYPE_FORWARD_CASE: 16,
    MESSAGE_TYPE_CLOSE_CASE: 17,
    MESSAGE_TYPE_REPLY_TO: 18,
    MESSAGE_TYPE_PERSONA_DATA_RESULT: 19,
    MESSAGE_TYPE_LOCATION: 20,
    MESSAGE_TYPE_SYSTEM: 22,
    MESSAGE_TYPE_AWAY_CHOICE: 23,
    MESSAGE_TYPE_APPOINTMENT_BEGIN: 24,
    MESSAGE_TYPE_APPOINTMENT_REQUEST: 25,
    MESSAGE_TYPE_ATTACHMENT: 30,

    SENDER_TYPE_BOT: 0,
    SENDER_TYPE_CUSTOMER : 1,
    SENDER_TYPE_ASSOCIATE : 2,
    SENDER_TYPE_SYSTEM: 3,

    // case's status
    CASE_REQUESTED: 1,
    CASE_ACCEPTED: 2,
    CASE_REJECTED: 3,
    CASE_CLOSED: 4,

    // case's priority
    CASE_PRIORITY_HIGH: 1,
    CASE_PRIORITY_NORMAL: 2,
    CASE_PRIORITY_LOW: 3,

    WORKING_STATUS_ACTIVE: 1,
    WORKING_STATUS_AWAY: 2,

    // sms
    SMS_SUCCESS: 'SMS has been sent',
    SMS_FAILED: 'Failed to send SMS',   
    SMS_PHONE_INVALID: 'Invalid phone number',
    SMS_PHONE_UNKNOWN: 'Phone number is not available',
    SMS_SENDER_ID_INVALID: 'SMS feature is disabled',

    // mms
    MMS_SUCCESS: 'MMS has been sent',
    MMS_FAILED: 'Failed to send MMS',

    MMS_MAX_FILE_SIZE: 700000, //kb,
    MMS_MAX_FILE_WIDTH: 1200, //px,

    MESSAGE_AWAY_CHOICE: 'You can wait until this associate come back or continue conversation with another associate.',
    MESSAGE_AWAY_CHOICE_CONTINUE: 'Continue conversation',
    MESSAGE_AWAY_CHOICE_NOTIFY: 'Notify me when associate come back',

    MESSAGE_AWAY_DECISION_DEFAULT: 0,
    MESSAGE_AWAY_DECISION_CONTINUE: 1,
    MESSAGE_AWAY_DECISION_NOTIFY: 2,

    COLLECTION_SETTINGS: 'settings',

    SYSTEM_MESSAGES: [8, 13, 15, 17, 19, 22, 23, 24],

    // archive
    ARCHIVE_RECURRENCE_TIME: '5:00 PM',

    PIGEON_PLATFORM_HELP_CENTER: 'https://pigeonplatform.notion.site/pigeonplatform/Pigeon-Platform-Help-Center-aa58fa25b2d148348bafa1cc73839a1c'
};

export default constants
