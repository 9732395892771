import {storage} from '@/plugins/firebase.init';
import {DataSet} from '@/data/repository';
import {Unsubscribe} from 'firebase/firestore';
import {getDownloadURL, ref, uploadBytes} from 'firebase/storage';
import {injectable, singleton} from 'tsyringe';

export interface ContactPhotoRepository {
  save(businessId: string, item: any): Promise<any>

  delete(businessId: string, item: any)

  exists(businessId: string, item: any): Promise<boolean>

  findAll(businessId: string, filter?: any): Promise<any[]>

  findAllAsync(businessId: string, callback: DataSet<any>): () => void

  update(businessId: string, item: any): Promise<any>;
}

@injectable()
@singleton()
export class ContactPhotoDataRepository implements ContactPhotoRepository {

  delete(businessId: string, item: any) {
  }

  async save(businessId: string, item: any): Promise<any> {
    const fileRef = ref(storage, `businesses/${businessId}/directory/${item.contactId}/photo/${item.file.name}`);
    const uploadTaskSnapshot = await uploadBytes(fileRef, item.file);
    return await getDownloadURL(uploadTaskSnapshot.ref);
  }

  async exists(businessId: string, item: any): Promise<boolean> {
    return false;
  }

  async findAll(businessId: string, filter?: any): Promise<any[]> {
    return [];
  }

  findAllAsync(businessId: string, callback: DataSet<any>): Unsubscribe {
    return () => {
    };
  }

  update(businessId: string, item: any): Promise<any> {
    return Promise.resolve(undefined);
  }
}


