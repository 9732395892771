import {FieldValue, Timestamp} from 'firebase/firestore';

export interface Permission {
  name: string
  granted: boolean
}

export interface BusinessContact extends BusinessDepartment {
  email?: string | null
  position?: string | null
  associate?: AssociateAccount | null
  defaultContact?: boolean | null
  associateIDs?: string[] | null
  stats?: Stats | null
  phoneNumber?: string | null
  address?: string | null
  isCustomer?: boolean | null
  isRemoved?: boolean | null
}

export interface PersonalContactItem {
  id: string,
  name: string
  email?: string
  position?: string
  phoneNumber?: string | null
  associate?: AssociateAccount | null
  photoUrl?: PhotoUrl | null
}

export interface Contact {
  name: string
  position?: string
  description?: string
  email: string
  phoneNumber: string
  photoFile?: File
  useProfilePhotoUrl?: boolean
}

export interface Status {
  online?: boolean
  lastChanged?: Timestamp | null
}

export interface BusinessDepartment {
  id?: string | null
  name: string
  description?: string | null
  photoUrl?: PhotoUrl | null
  // permissions?: Permission[] | null
  parentName?: string
  path?: string[]
  type: number | null
  contacts?: any[] | null
  expanded?: boolean
  business: BusinessItem | null
  rules?: Rules
  createdDate?: Timestamp | FieldValue | null
  level?: number
  index?: number
  flatIndex?: number
}

export interface User {
  uid: string | null
  displayName: string | null
  photoURL: string | null
}

export interface BusinessItem {
  id: string | null | undefined
  name: string | null | undefined
}

export interface PhotoUrl {
  thumbnail: string | null
  normal: string | null
}

export interface WorkingStatus {
  autocancel?: boolean | null
  duration?: number | null
  time?: Timestamp | null
  name: string
  type: number
}

export interface Roles {
  superAdmin: boolean
  admin: boolean
  associate: boolean
}

export interface Role {
  name: string
  value: number
}

export interface AssociateAccount extends UserAccount {
  awaySettings?: { useMessage: boolean, message: string } | null
  workingStatus?: WorkingStatus | null
  status?: Status | null
  business?: BusinessItem | null
  defaultContactId?: string | null
  disabled?: boolean | true
  hidden?: boolean | true
  description?: string | null
  admin?: boolean | null | any
  superAdmin?: boolean | null | any
  owner?: boolean | null
  header?: string | null
  inbox?: number | null
  stats?: Stats | null
  roles?: { associate: boolean, admin: boolean, superAdmin: boolean } | null
  position: string | null
  doNotDisturb?: Timestamp | any
  disableAppoint?: Timestamp | any
  firstVisit?: Timestamp | null
  discoverd?: boolean | null
}

export interface UserAccount {
  id?: string | null
  fullName: string | null
  email: string | null
  phoneNumber?: string | null
  address?: string | null
  ssn?: string | null
  dob?: Date | null
  zipCode?: string | null
  photoUrl?: PhotoUrl | null
  type?: number | null
}

export interface Geopoint {
  latitude: number
  longitude: number
}

export interface BusinessCategory {
  id?: string | null
  index: number
  name: string
  tag: string
  tags: string[]
}

export interface Business {
  id?: string | null
  name: string | null
  address: string | null
  street: string | null
  city: string | null
  state: string | null
  country?: string | null
  zipCode: string | null
  phone: string | null
  email: string | null
  website: string | null
  description?: string | null
  openHours?: string | null
  logoUrl?: string | null
  geopoint?: Geopoint | null
  category?: string | null
  businessCategory: BusinessCategory | null
  tags?: string[] | null
  createdDate?: Date | null
  updatedDate?: Date | null
  employees?: string | null
  taxId?: string | null
  verified?: boolean | null
  companyLogoUrl?: PhotoUrl | null
}

export interface RootState {
  version: string
  currentUser: User | null
  t2bUser: AssociateAccount | null
  businessCategories: BusinessCategory[] | null
  sourceBusiness: Business | null
  business: Business | null
  directory: BusinessContact[]
  originals: BusinessContact[]
  imagePlaceholder: string
  businessImageFile: File | null
  isActive: boolean,
  collections: any
  currentPage: number,
  latestUpdates: Update[]
}

export interface Update {
  versionName: string,
  buildDate: Timestamp,
  notes: string
}

export interface DashboardComponents {
  customersStats: number,
  customersRatings: number,
  casesCompletion: number,
  reactionsTimes: number,
  interactions: number,
  caseTypes: number,
  stuffPerformance: number
}

export interface AuthState {
  companies: any[]
  companiesError: string
  companiesLoading: boolean
  companyDetails: any
  detailsError: string
  detailsLoading: boolean
  loading: boolean
  error: string
  email: string
  oobCode: string | null
}

export interface ContactsState {
  selectedContact: BusinessContact | null
  businessDirectory: BusinessContact[]
  sourceBusinessDirectory: BusinessContact[]
  directoryOriginals: BusinessContact[]
  contacts: BusinessContact[]
  directoryNodes: object,
  // contactIsGroup: boolean,
  associatesOriginal: AssociateAccount[],
  selectedAssociate: AssociateAccount | null,
  showCreateEditDepartment: boolean
  directoryInEditMode: boolean
  selectedDepartment: BusinessDepartment | null,
  snackbar: Snackbar
  workingStates: WorkingState[]
  associateContacts: BusinessContact[]
  associateGroupContacts: BusinessContact[]
  contactsOwners: BusinessContact[]
  contactsAdmins: BusinessContact[]
  backupContacts: BusinessContact[]
  selectedCustomer: any | null
  customerContacts: any[],
  customersCount: number
  vipCustomersCount: number
  optinCustomersCount: number
  personalCount: number
  sharedCount: number
  pendingDeleteContact: BusinessContact[]
  pendingIndexUpdateContacts: BusinessContact[]
  pendingPathUpdateContacts: BusinessContact[]
  pendingUpdateDepartments: BusinessDepartment[]
}

export interface ArchiveState {
  archive: any[],
  selectedChatsArchive: any,
  selectedProfileArchive: any,
  archiveType: string,
  exporting: boolean,
  exportError: string,
  exportResult: any,
  caseMessages: any[],
  archiveCountAll: number,
  archiveCountPersonal: number,
  archiveCache: any
}

export interface WorkingState {
  name: string
  value: boolean
}

export interface Rules {
  NOTIFICATION?: {
    delay: number,
    notify: boolean,
    roundRobin?: boolean
  },
  VISIBILITY?: {
    visible: boolean
  },
  CONVERSATION?: {
    type: number
  }
}

export interface Snackbar {
  show: boolean
  timeout: number
  text: string | null
}


export enum SIZE_MAP {
  small = '16px',
  default = '24px',
  medium = '28px',
  large = '36px',
  xLarge = '40px'
}

export interface ConversationsState {
  messageType: any
  selectedLocation: { lat: number, lng: number }
  selectedLocationAddress?: string | null
  selectedChatId?: string | null
  selectedTextSession?: any
  messages: MessageUI[]
  messagesLoaded: boolean
  caseStatus: any
  fullConversation: boolean
  loadingMessages: boolean
  cachedLastTextSessions: any
  cachedMessages: any
  seenByMessages: any[]
}

export interface Stats {
  cases: number
  rating: number
  avgRating: number
}

export interface RequestsState {
  inbox: any[]
  inboxCountPersonal: number
  inboxCountAll: number
  rejected: any[]
  rejectedCountPersonal: number,
  rejectedCountOther: number,
  appointments: any[]
  appointmentsCountPersonal: number
  appointmentsCountBusiness: number
  appointmentsCountAll: number
  selectedAppointment: any,
  appoints: any,
  selectedRequest: any,
  requestFullPath: string,
  requestUnread: any[]
}

export interface ChatsState {
  activeChats: any[]
  inner: any[]
  activeCount: number
  activeCountAll: number
  activeCountPersonal: number
  innerCount: number
  innerCountAll: number
  innerCountPersonal: number
  videoCall: boolean
  videoMinimized: boolean
  videoSessionId?: string | null
  videoSession: any
  videoTotalSeconds: number
  videoMuted: boolean
  voiceMuted: boolean
  joiningVideoCall: boolean
  chatsNavPage: number
  error: string
  busy: boolean
  innerUnread: any[]
  activeUnread: any[]
}

export interface ChatStoreState {
  saving: boolean
  error: string
  noteReplies: any[]
  caseNotes: any[]
  customerNotes: any[]
  selectedNote: any
  repliesLoaded: boolean
  notesLoaded: boolean
  forwardedMessage: any
  forwardedMessages: any[] | null
  chatFullPath: string
}

export interface Settings {
  alwaysOpen?: boolean
  timeZone: string
  timeZoneIndex: number
  workingDays: WorkingDay[]
  accessProtection: BusinessPageAccessSetting
  autoAlert: AlertSetting
  away: AwaySetting,
  welcome: WelcomeSetting,
  backup: BackupSetting
  appoints: AppointsSetting
  contacts: ContactsSetting
  liveChat: LiveChatSetting
  autoArchive: ArchiveSetting
}

export interface ContactsSetting {
  autoSharing: boolean
}

export interface WorkingDay {
  name: string
  from: string
  to: string
  start: string // deprecated
  end: string // deprecated
  active: boolean
}

export interface AppointDay extends WorkingDay {
  period: number
}

export interface AlertSettingContact {
  id: string
  uid: string
  name: string
  email?: string
  position?: string
  photoUrl?: PhotoUrl | null
}

export interface BusinessPageAccessSetting {
  active: boolean
  code: string
}

export interface AlertSetting {
  active: boolean
  contacts: AlertSettingContact[] | null
  emails: string[] | null
  // new fields
  requestNew: boolean,
  requestIdle: boolean,
  requestIdleTime: number,
  requestIdleRepeat: number,
  chatExternalUnread: boolean,
  chatExternalUnreadTime: number,
  chatExternalUnreadRepeat: number,
  chatExternalIdle: boolean,
  chatExternalIdleTime: number,
  chatExternalIdleRepeat: number,
  chatInternalUnread: boolean,
  chatInternalUnreadTime: number,
  chatInternalUnreadRepeat: number,
  appointmentNew: boolean,
  appointmentReminder: boolean,
  appointmentReminderTime: number,
  appointmentReminderRepeat: number,
  summaryDailyActivity: boolean,
  summaryDailyBusiness: boolean
}

export interface AwaySetting {
  active: boolean  | false 
  closedMessage: string | null
  awayMessage: string | null
}

export interface WelcomeSetting {
  active: boolean | false 
  welcomeMessage: string | null
}

export interface BackupSetting {
  active: boolean
  contact: BusinessContact | null | { id?: string | null, uid, business, name, email, photoUrl, position, workingStatus, status, type, added }
  autoForward: boolean
  autoGroup: boolean
}

export interface AppointsSetting {
  active: boolean
  contact: BusinessContact | null | { id?: string | null, uid, business, name, email, photoUrl, position, workingStatus, status, type, added }
  appointDays: WorkingDay[]
  period: number
  remind: string | null
  syncGoogle: boolean
}

export interface SettingsState {
  settings: Settings | null
}

export interface DefaultContact {
    id: string
    uid: string
    name: string
    email?: string
    position?: string
    photoUrl?: PhotoUrl | null,
    online?: boolean
}

export interface LiveChatSetting {
  active: boolean
  contactDisplay: boolean
  defaultContact: boolean  
  contacts: DefaultContact[] | null
}

export interface ArchiveSetting {
  active: boolean  
  emails: string[] | null

  // new fields
  isExportOpenCases: boolean
  recurrenceType: string
  recurrenceTime: string
}

export const messageType = {
  STANDARD: 1,
  FORWARD: 10,
  BUSINESS_CARD: 12,
  REQUEST_PERSONAL_DATA: 13,
  IMAGE: 14,
  ADD_PERSON_TO_GROUP: 15,
  FORWARDED_CASE: 16,
  REPLY_TO: 18,
  PERSONA_DATA_RESULT: 19,
  LOCATION: 20,
  VIDEO_CALL_START: 26,
  VIDEO_CALL_END: 27,
};

export const senderType = {
  BOT: 0,
  CUSTOMER: 1,
  ASSOCIATE: 2,
  SYSTEM: 3
}

export enum NotificationsType {
  INFO = 'info',
  WARNING = 'warning',
  ISSUE = 'issue',
  SUCCESS = 'success',
  MAILED = 'mailed'
}

export enum NotificationsPositionVertical {
  TOP = 'top',
  MIDDLE = 'middle',
  BOTTOM = 'bottom',
}

export enum NotificationsPositionHorizontal {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right'
}

export const NotificationTimeout = {
  SHORT: 3000,
  LONG: 5000
}

export interface UndoSystem {
  id: string
  type: string
  text: string
  data: any
  callback: any
  timeout?: number | null
}

export interface NotificationsPosition {
  horizontal: NotificationsPositionHorizontal,
  vertical: NotificationsPositionVertical
}

export interface NotificationsSystem {
  type: string
  text: string
  icon?: string | null
  timeout?: number | null
  position?: NotificationsPosition | null
}

export interface NotificationsState {
  notificationsSystem: NotificationsSystem[]
}

export interface CustomersQuantity {
  optedIn: number | null
  total: number | null
  optedInDiff: number | null
  totalDiff: number | null
  timestamp: Timestamp | null
}

export interface CustomersRatings {
  avgRating: number
  oneStar: number
  twoStar: number
  threeStar: number
  fourStar: number
  fiveStar: number
  totalRating: number
  createdDate: Timestamp | null
}

export interface CasesCompletion {
  opened: number
  closed: number
  createdDate: Timestamp | null
}

export interface ResponseTime {
  acceptTime: number
  diffAcceptTime: number
  diffForwardTime: number
  diffRejectTime: number
  diffResolveTime: number
  diffResponseTime: number
  forwardTime: number
  rejectTime: number
  resolveTime: number
  responseTime: number
  weekday: number
  createdDate: Timestamp | null
}

export interface Interactions {
  diffAppoints: number
  diffCases: number
  newAppoints: number
  openedCases: number
  weekday: number
  createdDate: Timestamp | null
}

export interface CaseTypeData {
  cases: { [field: string]: number }
  createdDate: Timestamp | null
}

export interface CaseType {
  depName: string
  numCases: number
}

export interface ChannelMember {
  id: string,
  name: string,
  photoUrl?: string | null
}

export interface Channel {
  id?: string | null
  business?: BusinessItem | null
  name: string
  description?: string
  imageUrl?: string | null
  createdDate?: Timestamp
  updatedDate?: Timestamp | null
  memberIDs?: string[]
  members?: ChannelMember[]
  createdBy: { id, name }
  disabled: boolean
  lastMessage?: any
}

export interface ChannelsState {
  bizChannels: Channel[]
  loadingChannel: boolean
  selectedChannel: Channel | null
  selectedChannelMembers: any[] | null
  nonSelectedChannelMembers: any[] | null
  cachedChannelMessages: any
  channelMessages: any[]
  channelMessagesLoaded: boolean
}

export enum GoogleMapStatus {
  NOT_LOADED = 0,
  LOADING = 1,
  LOADED = 2,
  ERROR = 3
}

export enum LoadingStatus {
  NOT_LOADED = 0,
  LOADING = 1,
  LOADED = 2,
  ERROR = 3
}

export enum RolesList {
  superAdmin = 0,
  admin = 1,
  associate = 2
}

export interface MessageUI {
  id: string
  data: any
  selected: boolean
  previousCaseMessage: boolean
  isSectionTimestamp: boolean
  sectionTimestamp?: string | null
  createdAsTimestamp: string
  createdAtMillis: number
  kind: string
  showName: boolean
}
