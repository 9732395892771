import {storage} from '@/plugins/firebase.init';
import {DataSet, Repository} from '@/data/repository';
import {Unsubscribe} from 'firebase/firestore';
import {injectable, singleton} from 'tsyringe';
import {getDownloadURL, ref, uploadBytes} from 'firebase/storage';

export interface PhotoRepository extends Repository<any, string> {
}

@injectable()
@singleton()
export class PhotoDataRepository implements PhotoRepository {

  delete(item: any) {
  }

  async save(item: any): Promise<any> {
    const fileRef = ref(storage, `users/${item.associateId}/profilePhoto/${item.file.name}`);
    const uploadTaskSnapshot = await uploadBytes(fileRef, item.file);
    return await getDownloadURL(uploadTaskSnapshot.ref);
  }

  async exists(item: any): Promise<boolean> {
    return false;
  }

  async findAll(filter?: any): Promise<any[]> {
    return [];
  }

  findAllAsync(callback: DataSet<any>): Unsubscribe {
    return () => {
    };
  }

  update(item: any): Promise<any> {
    return Promise.reject('not implemented');
  }
}


