import {AxiosStatic} from 'axios';
import {Auth, getIdToken} from 'firebase/auth';

export interface BlockUserRequest {
  customerId: string
  associateId: string
  businessId: string
}

export type BlockUserUseCase = (request: BlockUserRequest) => Promise<boolean>

export const blockUserUseCase = (auth: Auth, axios: AxiosStatic): BlockUserUseCase => {
  return async (request: BlockUserRequest): Promise<boolean> => {
    const {customerId, associateId, businessId} = request
    if (!customerId || !associateId || !businessId) {
      return false
    }
    try {
      const currentUser = auth.currentUser;
      const token = currentUser ? await getIdToken(currentUser, false) : '';
      const axiosResponse = await axios.post('/BusinessService.blockCustomer',
        {customerId, associateId, businessId},
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      return axiosResponse.status === 200
    } catch (err) {
      console.error(err)
    }
    return false
  }
}
