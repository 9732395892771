import Vue from 'vue';
import Vuex, {StoreOptions} from 'vuex';
import contacts from '@/store/modules/contacts';
import conversations from '@/store/modules/conversations';
import chats from '@/store/modules/chats';
import {RootState} from '@/domain/model/types';
import {auth} from '@/store/modules/auth';
import {home} from '@/store/modules/home';

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
    modules: {
        auth,
        home,
        contacts,
        conversations,
        chats
    }
};

export default new Vuex.Store<RootState>(store);

// export default new Vuex.Store({
//     modules: {application, contacts, conversations, requests, chats}
// });
