









import {Component, Vue} from 'vue-property-decorator';

@Component({
  name: 'app'
})
export default class App extends Vue {
  mounted() {
    console.log('APP :: mounted');
  }

  created() {
    console.log('APP :: created');
  }
}
