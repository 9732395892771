import {addDoc, Firestore, Timestamp, UpdateData, updateDoc} from 'firebase/firestore';
import {Channel} from '@/domain/model/types';
import {inject, injectable, singleton} from 'tsyringe';
import {businessChannel, businessChannels} from '@/data/firebase';

export interface ChannelsRepository {
  save(businessId: string, item: Channel): Promise<string | null>

  update(businessId: string, channelId: string, item: Channel): Promise<string | null>
}

@injectable()
@singleton()
export class ChannelsDataRepository implements ChannelsRepository {
  private readonly db: Firestore

  constructor(@inject('FirebaseFirestore') db: Firestore) {
    this.db = db
  }

  public async save(businessId: string, item: Channel): Promise<string | null> {
    item.createdDate = Timestamp.now()
    item.updatedDate = item.createdDate
    try {
      const res = await addDoc(businessChannels(businessId), item)
      return res.id
    } catch (e) {
      console.error(e)
    }
    return null
  }

  public async update(businessId: string, channelId: string, item: Channel): Promise<string | null> {
    item.updatedDate = Timestamp.now()
    const data: UpdateData<any> = {
      name: item.name,
      description: item.description,
    }
    if (item.hasOwnProperty('imageUrl')) {
      data.imageUrl = item.imageUrl
    }
    try {
      await updateDoc(businessChannel(businessId, channelId), data)
      return channelId
    } catch (e) {
      console.error(e)
    }
    return null
  }
}
