import {ChannelsRepository} from '@/data/channelsDataRepository';
import {Channel} from '@/domain/model/types';
import {UploadChannelImageUseCase} from '@/domain/uploadChannelImageUseCase';

export interface EditChannelRequest {
  businessId: string
  channelId: string
  description: string
  imageFile: File | null
  name: string
}

export type EditChannelUseCase = (request: EditChannelRequest) => Promise<string | null>

export const editChannelUseCase =
  (channelsRepository: ChannelsRepository, uploadImage: UploadChannelImageUseCase): EditChannelUseCase => {
    return async (request: EditChannelRequest): Promise<string | null> => {
      const businessId = request.businessId;
      const channel: Channel = {
        createdBy: {id: undefined, name: undefined},
        disabled: false,
        business: {id: businessId, name: ''},
        description: request.description,
        name: request.name
      }
      const channelId = request.channelId;
      const imageFile = request.imageFile;
      if (imageFile) {
        channel.imageUrl = await uploadImage({businessId, channelId, imageFile})
      }
      return await channelsRepository.update(businessId, channelId, channel)
    }
  }
