import {Module} from 'vuex';
import {RootState} from '@/domain/model/types';
import {getters} from '@/store/modules/home/getters';
import {mutations} from '@/store/modules/home/mutations';
import {actions} from '@/store/modules/home/actions';

export interface HomeState {
    fulfilled: string[]
    todo: string[]
    error: string
    busy: boolean
    step?: string | null
}

const state: HomeState = {
    fulfilled: [],
    todo: [],
    error: '',
    busy: false,
    step: null
};

export const home: Module<HomeState, RootState> = {
    state,
    getters,
    mutations,
    actions
};
