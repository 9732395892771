import {ChannelsRepository} from '@/data/channelsDataRepository';
import {Channel} from '@/domain/model/types';
import {ChannelsImagesRepository} from '@/data/channelsImagesDataRepository';
import {UploadChannelImageUseCase} from '@/domain/uploadChannelImageUseCase';

export interface CreateChannelRequest {
  businessId: string
  businessName: string
  description: string
  imageFile: File | null
  name: string
  associateId: string
  associateName: string
}

export type CreateChannelUseCase = (request: CreateChannelRequest) => Promise<string | null>

export const createChannelUseCase = (
  channelsRepository: ChannelsRepository,
  uploadImage: UploadChannelImageUseCase
): CreateChannelUseCase => {
  return async (request: CreateChannelRequest): Promise<string | null> => {
    const businessId = request.businessId;
    const channel: Channel = {
      disabled: false,
      createdBy: {id: request.associateId, name: request.associateName},
      business: {id: businessId, name: request.businessName},
      description: request.description,
      name: request.name
    }
    const channelId = await channelsRepository.save(businessId, channel);
    const imageFile = request.imageFile;
    if (channelId && imageFile) {
      channel.imageUrl = await uploadImage({businessId, channelId, imageFile})
      await channelsRepository.update(businessId, channelId, channel);
    }
    return channelId
  }
}
