import FactoryProvider from 'tsyringe/dist/typings/providers/factory-provider';
import DependencyContainer from 'tsyringe/dist/typings/types/dependency-container';
import {injectable, singleton} from 'tsyringe';
import {reInviteUserUseCase, ReInviteUserUseCase} from '@/domain/reInviteUserUseCase';

@injectable()
@singleton()
export default class ReInviteUserUseCaseFactory implements FactoryProvider<ReInviteUserUseCase> {
  public useFactory(dc: DependencyContainer): ReInviteUserUseCase {
    return reInviteUserUseCase(dc.resolve('AssociatesRepository'));
  }
}
