import {BaseClass} from '@/domain/model/base_object';

export class Invite extends BaseClass {
    public index: number;
    public id: string;
    public email: string;
    public role?: any;
    public mode?: string;
    public createDate?: number;

    constructor(index: number, id: string, email: string, mode?: string, role?: any, createDate?: number) {
        super();
        this.email = email;
        this.mode = mode;
        this.role = role;
        this.createDate = createDate;
        this.id = id;
        this.index = index;
    }
}

export const createInvite = () => new Invite(-1, '', '')
