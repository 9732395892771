import {BusinessContact} from '@/domain/model/types';
import {DocumentData} from 'firebase/firestore'

export class ContactMapper {
  public static map(doc: DocumentData): BusinessContact {
    return {
      business: doc.business,
      name: doc.name,
      position: doc.position,
      email: doc.email,
      phoneNumber: doc.phoneNumber,
      associate: doc.associate,
      associateIDs: doc.associateIDs,
      type: doc.type,
      flatIndex: doc.flatIndex,
      rules: doc.rules,
      expanded: false
    };
  }
}
