import {DependencyContainer, FactoryProvider, injectable, singleton} from 'tsyringe';
import {EditChannelUseCase, editChannelUseCase} from '@/domain/editChannelUseCase';

@injectable()
@singleton()
export default class EditChannelUseCaseFactory implements FactoryProvider<EditChannelUseCase> {
  public useFactory(dc: DependencyContainer): EditChannelUseCase {
    return editChannelUseCase(dc.resolve('ChannelsRepository'), dc.resolve('UploadChannelImage'));
  }
}
