import {MutationTree} from 'vuex';
import {HomeState} from '@/store/modules/home/index';

export const mutations: MutationTree<HomeState> = {
    setFulfilled(state, payload: string[]) {
        state.fulfilled = payload;
    },
    setTodo(state, payload: string[]) {
        state.todo = payload;
    },
    setStep(state, payload: string) {
        state.step = payload;
    },
    setBusy(state) {
        state.error = '';
        state.busy = true;
    },
    setRelease(state) {
        state.busy = false;
    },
    setError(state, payload: string) {
        state.error = payload;
        state.busy = false;
    },
    resetSetupState(state) {
        state.fulfilled = [];
        state.todo = [];
        state.error = '';
        state.busy = false;
        state.step = null;
    }

};
