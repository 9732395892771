import {AssociatesRepository} from '@/data/associatesDataRepository';

export type ReInviteUserUseCase = (userId: string) => Promise<void>

export const reInviteUserUseCase = (associatesRepository: AssociatesRepository): ReInviteUserUseCase => {
  return async (userId: string): Promise<void> => {
    const associate = await associatesRepository.findById(userId);
    associate!.invitedAt = new Date()
    await associatesRepository.update(associate!)
  }
}
