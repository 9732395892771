import {Module} from 'vuex';
import {AuthState, RootState} from '@/domain/model/types';
import {actions} from '@/store/modules/auth/actions';
import {mutations} from '@/store/modules/auth/mutations';
import {getters} from '@/store/modules/auth/getters';

const state: AuthState = {
  oobCode: '',
  email: '',
  error: '',
  loading: false,
  companyDetails: null,
  detailsError: '',
  detailsLoading: false,
  companies: [],
  companiesError: '',
  companiesLoading: false
};

const namespaced: boolean = true;

export const auth: Module<AuthState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
