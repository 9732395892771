import Vue from 'vue';

Vue.directive('click-outside', {
  bind: (el: any, binding, vnode) => {
    el.clickOutsideEvent = (event) => {
      // here I check that click was outside the el and his children
      if (!(el === event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        // @ts-ignore
        const contextElement = vnode.context[binding.expression];
        if (typeof contextElement === 'function') {
          contextElement(event);
        }
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: (el: any) => {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
});

Vue.directive('on-resize', {
  bind: (el: any, binding, vnode) => {
    el.resizeEvent = (event) => {
      // @ts-ignore
      const contextElement = vnode.context[binding.expression];
      if (typeof contextElement === 'function') {
        contextElement(event);
      }
    }
    document.defaultView?.addEventListener('resize', el.resizeEvent)
  },
  unbind: ((el: any) => {
    document.defaultView?.removeEventListener('resize', el.resizeEvent)
  })
})

Vue.directive('on-wheel', {
  bind: (el: any, binding, vnode) => {
    el.wheelEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        // @ts-ignore
        const contextElement = vnode.context[binding.expression];
        if (typeof contextElement === 'function') {
          contextElement(event);
        }
      }
    };
    document.body.addEventListener('wheel', el.wheelEvent)
  },
  unbind: (el: any) => {
    document.body.removeEventListener('wheel', el.wheelEvent)
  },
})
