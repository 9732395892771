import {DataSet} from '@/data/repository';
import {
  deleteDoc,
  Firestore,
  getDoc,
  getDocs,
  query,
  Query,
  setDoc,
  Timestamp,
  Unsubscribe,
  where
} from 'firebase/firestore';
import {createInvite, Invite} from '@/domain/model/invite';
import {InviteMapper} from '@/data/mappers/inviteMapper';
import {inject, injectable, singleton} from 'tsyringe';
import {businessInvite, businessInvites} from '@/data/firebase';

export interface InvitesRepository {
  save(business: { id, name }, item: Invite): Promise<Invite>

  delete(businessId: string, item: Invite)

  exists(businessId: string, item: Invite): Promise<boolean>

  findAll(businessId: string, filter?: any): Promise<Invite[]>

  findById(businessId: string, inviteId: string): Promise<Invite>

  findAllAsync(businessId: string, callback: DataSet<Invite>): () => void

  update(businessId: string, item: Invite): Promise<Invite>;
}

@injectable()
@singleton()
export class InvitesDataRepository implements InvitesRepository {
  private readonly db: Firestore

  constructor(@inject('FirebaseFirestore') db: Firestore) {
    this.db = db
  }

  public async findById(businessId: string, inviteId: string): Promise<Invite> {
    const snapshot = await getDoc(businessInvite(businessId, inviteId))
    if (!snapshot.exists()) {
      return createInvite()
    }
    return new Invite(-1, snapshot.id, snapshot.data()!.email)
  }

  public async delete(businessId: string, item: Invite) {
    return await deleteDoc(businessInvite(businessId, item.id))
  }

  public async save(business: { id, name }, item: Invite): Promise<Invite> {
    await setDoc(businessInvite(business.id, item.id),
      {
        ...item.data(),
        createDate: Timestamp.now()
      })
    return item;
  }

  public async exists(businessId: string, item: Invite): Promise<boolean> {
    const invite = await getDoc(businessInvite(businessId, item.id));
    return invite.exists();
  }

  public async findAll(businessId: string, filter?: any): Promise<Invite[]> {
    let invitesQuery: Query = businessInvites(businessId);
    if (!!filter) {
      invitesQuery = query(invitesQuery, where(filter.field, filter.operator, filter.value))
    }
    const querySnapshot = await getDocs(invitesQuery)
    return querySnapshot.docs.map((snapshot) => {
      const invite = InviteMapper.map(snapshot.data());
      invite.id = snapshot.id;
      return invite;
    });
  }

  public findAllAsync(businessId: string, callback: DataSet<Invite>): Unsubscribe {
    return () => {
    };
  }

  public update(businessId: string, item: Invite): Promise<Invite> {
    return Promise.reject('not implemented');
  }
}


