<template>
  <svg width="16px" height="17px" viewBox="0 0 16 17" xmlns="http://www.w3.org/2000/svg">
    <g id="Compositions" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Business:-User-management" transform="translate(-376.000000, -262.000000)">
        <g id="Table" transform="translate(344.000000, 120.000000)">
          <g id="styles/icons/checkbox/_active-copy-5" transform="translate(32.000000, 142.500000)">
            <rect id="Rectangle-Copy" fill="#3773F5" x="0" y="0" width="16" height="16" rx="2"/>
            <path
                d="M11.8837104,3.3318783 L13.297924,4.74609186 L7.64607605,10.3968783 L7.65182352,10.4029461 L6.23760995,11.8171597 L6.23207605,11.8108783 L6.22685614,11.8171597 L4.81264258,10.4029461 L4.81807605,10.3968783 L2.70207605,8.28162577 L4.11628961,6.8674122 L6.23207605,8.9828783 L11.8837104,3.3318783 Z"
                id="Combined-Shape" fill="#FFFFFF"/>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "ic-checkbox-on"
}
</script>

<style scoped>

</style>
