import {BusinessContact} from '@/domain/model/types';
import {Timestamp} from 'firebase/firestore';
import {getDownloadURL, ref, uploadBytes} from 'firebase/storage';
import {storage} from '@/plugins/firebase.init';
import axios from '@/plugins/axios';
import constants from '@/common/constants';
import Constants from '@/plugins/constants';

const URL_REGEXP = /(https?:\/\/[^\s]+)/g;
const MONTHS: string[] = ['Jan', 'Feb', 'Mar', ' Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
const env: any = process.env;

interface IResizeImageOptions {
  maxSize: number;
  file: File;
};


export const twoChars = (text) => {
  if (!text || !text.length) {
    return '';
  }
  const split = text.split(' ', 2);
  return `${split[0] ? split[0][0].toUpperCase() : ''}${split[1] ? split[1][0].toUpperCase() : ''}`;
};

export const randomise = () => {
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let text = '';
  for (let i = 0; i < 7; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
};

export const compareContacts = (c1, c2) => {
  return c1.type > c2.type ? 1 : (c2.type > c1.type ? -1 : (c1.name > c2.name ? 1 : (c2.name > c1.name ? -1 : 0)));
};

export const appendLeadingZeroes = (n) => {
  if (n <= 9) {
    return '0' + n;
  }
  return n;
};

function fallbackCopyTextToClipboard(text) {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }
  document.body.removeChild(textArea);
}

export const copyTextToClipboard = (text) => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  return navigator.clipboard.writeText(text);
};


export const downloadFile = ({name, storageUri}) => {
  const storageRef = ref(storage, storageUri)
  getDownloadURL(storageRef)
      .then((url) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = (event) => {
          const blob = xhr.response;
          const data = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = data;
          link.download = name;
          link.dispatchEvent(
              new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window
              })
          );
          setTimeout(() => {
            window.URL.revokeObjectURL(data);
            link.remove();
          }, 100);
        };
        xhr.open('GET', url);
        xhr.send();
      })
      .catch((e) => {
        console.log(e)
      });
}

export function urlify(text?: string, title: string = '') {
  if (!text) {
    return '';
  }
  return text.replace(URL_REGEXP, '<a href="$1" target="_blank" style="color: white;">$1</a>');
}

export function firstWord(text: any) {
  if (!text) {
    return '';
  } else {
    text = !!text.name ? text.name : text
    return text.split(' ')[0];
  }
}

export function titleCase(text: any) {
  if (!text) {
    return '';
  } else {    
    text = text.toLowerCase().replace(/\b[a-z]/g, function(letter) {
      return letter.toUpperCase();
    });
    return text
  }
}

export function timeZoneAndTime(date: Date) {
  const {hours, minutes, ampm} = extractDateParams(date)
  const offset = -date.getTimezoneOffset() / 60;
  const sign = offset > 0 ? '+' : ''
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
  return `(UTC${sign}${offset}) ${tz} ${hours}:${pad(minutes)} ${ampm}`
}

export function isFuture(date: Date): boolean {
  const now = new Date();
  now.setHours(0, 0, 0);
  return date >= now;
}

export function computeTimeWithOffset(date: Date, offset: number, offsetTxt: string, tz: string) {
  const tzDifference = offset * 60 + date.getTimezoneOffset();
  const dateWithOffset = new Date(date.getTime() + tzDifference * 60 * 1000);
  const {hours, minutes, ampm} = extractDateParams(dateWithOffset)
  return `(${offsetTxt}) ${tz} ${hours}:${pad(minutes)} ${ampm}`
}

export function dateToYMD(date): string {
  const d = date.getDate();
  const m = date.getMonth() + 1; // Month from 0 to 11
  const y = date.getFullYear();
  return `${y}-${(m <= 9 ? '0' + m : m)}-${(d <= 9 ? '0' + d : d)}`;
}

export function dateToMDY(date): string {
  const d = date.getDate();
  const m = date.getMonth() + 1; // Month from 0 to 11
  const y = date.getFullYear();
  return `${m}/${d}/${y}`;
}

export function dateToMMDDY(date): string {
  const d = date.getDate();
  const m = date.getMonth() + 1; // Month from 0 to 11
  const y = date.getFullYear();
  return `${(m <= 9 ? '0' + m : m)}/${(d <= 9 ? '0' + d : d)}/${y}`;
}

export function dateToYMD_HM(date) {
  const d = date.getDate();
  const m = date.getMonth() + 1; // Month from 0 to 11
  const y = date.getFullYear();
  const h = date.getHours();
  const min = date.getMinutes();
  return '' + y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d) +
    ' ' + (h <= 9 ? '0' + h : h) + ':' + (min <= 9 ? '0' + min : min);
}

export function timeToFrom(targetDate: number,
                           defaultValue: string | null = '',
                           toTime: boolean = true): string | null {
  // Get todayDate's date and time
  const now = new Date().getTime();
  // Find the distance between now and the count down date
  const distance = !!targetDate ? (toTime ? targetDate - now : now - targetDate) : 0;
  // Time calculations for days, hours, minutes and seconds
  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((distance % (1000 * 60)) / 1000);
  let timer = defaultValue;
  if (days > 0) {
    // if (days > 1) {
    //     return defaultValue
    // }
    if (days < 2) {
      timer = `${days} day`;
    } else {
      timer = `${days} days`;
    }
  } else if (hours > 0) {
    if (hours < 2) {
      timer = `${hours} hour`;
    } else {
      timer = `${hours} hours`;
    }
  } else if (minutes > 0) {
    if (minutes < 2) {
      timer = `${minutes} minute`;
    } else {
      timer = `${minutes} minutes`;
    }
  } else if (seconds > 0) {
    if (seconds < 2) {
      timer = `${seconds} second`;
    } else {
      timer = `${seconds} seconds`;
    }
  } else if (distance <= 0) {
    timer = defaultValue;
  }
  return timer;
}

export const formatTimestamp = (timestamp: Timestamp | null) => {
  if (timestamp === null) { return '' }
  const now = new Date()
  const current = timestamp.toDate()
  const distance = now.getTime() - current.getTime()
  if (Math.floor(distance / (1000 * 60 * 60 * 24)) < 1) {
    return current.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    })
  }
  const {year, month, day} = extractDateParams(current)
  if (now.getFullYear() === current.getFullYear()) {
    return `${MONTHS[month]} ${day}`
  }
  return `${MONTHS[month]} ${day} ${year}`
}

export const timestampToMDY = (timestamp: Timestamp) => {
  const current = timestamp.toDate()
  const {year, month, day} = extractDateParams(current)
  return `${MONTHS[month]} ${day}, ${year}`
}

export const formatMuted = (time: any) => {
  if (!time) {
    return 'Not set'
  }
  const nowMillis = new Date().getTime()
  const targetMillis = time.toMillis();
  const difHours = (targetMillis - nowMillis) / (60 * 60 * 1000);
  if (difHours <= 0) {
    return 'Not set'
  }
  return difHours <= 24 ? `Muted for ${timeToFrom(targetMillis)}` : 'Until I turn it back'
}

export const uuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // tslint:disable-next-line:no-bitwise
    const r = Math.random() * 16 | 0;
    // tslint:disable-next-line:no-bitwise
    const v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
};

export const encodeEmail = (email: string) => {
  const strings = email.split('@');
  const namePart = strings[0];
  const first = namePart[0];
  const last = namePart[namePart.length - 2];
  const middle = namePart.substring(1, namePart.length - 1).replace(/./g, '*');
  return first + middle + last + '@' + strings[1];
};

export const parseURL = (url: string) => {
  const parser = document.createElement('a');
  const queryObject = {};
  let queries;
  let split;
  let i;
  // Let the browser do the work
  parser.href = url;
  // Convert query string to object
  queries = parser.search.replace(/^\?/, '').split('&');
  for (i = 0; i < queries.length; i++) {
    split = queries[i].split('=');
    queryObject[split[0]] = split[1];
  }
  return {
    protocol: parser.protocol,
    host: parser.host,
    hostname: parser.hostname,
    port: parser.port,
    pathname: parser.pathname,
    search: parser.search,
    queryObject,
    hash: parser.hash
  };
};

export function pad(val: any) {
  const valString = val + '';
  if (valString.length < 2) {
    return '0' + valString;
  } else {
    return valString;
  }
}

function extractDateParams(date: Date) {
  const day: number = date.getDate()
  const month: number = date.getMonth()
  const year: number = date.getFullYear()
  let hours: number = date.getHours()
  const minutes: number = date.getMinutes()
  // Check whether AM or PM
  const ampm = hours >= 12 ? 'PM' : 'AM';
  // Find current hour in AM-PM Format
  hours = hours % 12;
  // To display "0" as "12"
  hours = hours ? hours : 12;
  return {day, month, year, hours, minutes, ampm};
}

export const formatMonth = (date?: Date | string): string => {
  if (!date) {
    return ''
  }
  const {month} = date instanceof Date ? extractDateParams(date) : extractDateParams(new Date(date));
  return `${MONTHS[month]}`
}

export const formatMonthDayYear = (date?: Date | string): string => {
  if (!date) {
    return ''
  }
  const {day, month, year} = extractDateParams(date instanceof Date ? date : new Date(date));
  return `${MONTHS[month]} ${day} ${year}`
}

export const formatNoteDate = (date?: Date | string): string => {
  if (!date) {
    return ''
  }
  const {day, month, year, hours, minutes, ampm} = extractDateParams(date instanceof Date ? date : new Date(date));
  const zeroMinute: string = (minutes < 10 ? '0' : '');
  return `${MONTHS[month]} ${day} ${year} ${hours}:${zeroMinute}${minutes} ${ampm}`
}

export const formatSectionDate = (date?: Date): string => {
  if (!date) {
    return ''
  }
  const {day, month, year, hours, minutes, ampm} = extractDateParams(date);
  const zeroMinute: string = (minutes < 10 ? '0' : '');
  if (new Date().getFullYear() !== year) {
    return `${MONTHS[month]} ${day} ${year}, ${hours}:${zeroMinute}${minutes} ${ampm}`
  }
  return `${MONTHS[month]} ${day} ${hours}:${zeroMinute}${minutes} ${ampm}`
}

export const formatArchiveDate = (date?: Date): string => {
  if (!date) {
    return ''
  }
  const {hours, minutes, ampm} = extractDateParams(date);
  const zeroMinute: string = (minutes < 10 ? '0' : '');  
  return dateToMDY(date) +  ` ${hours}:${zeroMinute}${minutes} ${ampm}`
}

export const counterFormatter = (counter) => {
  return counter >= 100 ? '99+' : counter
}

export const extractDate = (date?: Date): string => {
  if (!date) {
    return ''
  }
  const {day, month, year} = extractDateParams(date);
  return `${month + 1}/${day}/${year}`
}

export const extractTime = (date?: Date): string => {
  if (!date) {
    return ''
  }
  const {hours, minutes, ampm} = extractDateParams(date);
  return `${hours} ${pad(minutes)} ${ampm}`
}

export const equalDates = (src?: Date, target?: Date): boolean => {
  if (!src && !target) {
    return true
  }
  if (!src) {
    return false
  }
  if (!target) {
    return false
  }
  return src.getFullYear() === target.getFullYear() &&
    src.getMonth() === target.getMonth() &&
    src.getDate() === target.getDate()
}

export const equalNow = (target?: Date): boolean => {
  return equalDates(new Date(), target)
}

export const compareMembersFn = (a: any, b: any) => {
  const result: number = b.type - a.type
  if (!!result) {
    return result
  }
  const n1: string = a.name
  const n2: string = b.name
  return n1.localeCompare(n2)
};

export const deepCopy = (inObject) => {
  let outObject
  let value
  let key

  if (typeof inObject !== 'object' || inObject === null) {
    return inObject // Return the value if inObject is not an object
  }

  // Create an array or object to hold the values
  outObject = Array.isArray(inObject) ? [] : {}

  // tslint:disable-next-line:forin
  for (key in inObject) {
    value = inObject[key]
    // Recursively (deep) copy for nested objects, including arrays
    outObject[key] = deepCopy(value)
  }

  return outObject
}


export const flattenDirectory = (directoryTree: BusinessContact[], flatDir: BusinessContact[]) => {
  const length = directoryTree.length;
  for (let index = 0; index < length; index++) {
    const item: BusinessContact = directoryTree[index]
    if (item.type === 3 && item.contacts?.length) {
      flatDir.push({...item, contacts: null})
      flattenDirectory(item.contacts, flatDir)
    } else {
      flatDir.push(item)
    }
  }
}

export const formatDOB = (date?: Date): string => date ? `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}` : 'n/a'

export const formatMonthDay = (date?: Date): string => date ? `${date.getMonth() + 1}/${date.getDate()}` : 'n/a'

const pathPredicate = (searchPath: string): (item: any) => boolean => {
  return (item: any): boolean => item.path.startsWith(searchPath) && item.path.length === searchPath.length
}
// todo: revise and enhance algo!
export const findLastFlatIndex = (paths: any[], newPath: string[], sourcePath: string[]): any => {
  // get las department path element
  const lastPathEl = sourcePath.pop();
  if (lastPathEl) {
    // put path element to new path
    newPath.unshift(lastPathEl);
    // construct search string from source path
    const searchPath = sourcePath.join('/');
    if (!searchPath) {
      // if source path is empty - return
      return {
        flatIndex: paths.length - 1,
        parentPath: sourcePath
      }
    }
    const lastItem = paths.find(pathPredicate(searchPath));
    if (!lastItem) {
      return findLastFlatIndex(paths, newPath, sourcePath);
    }
    return {
      flatIndex: lastItem.index,
      parentPath: sourcePath
    }
  }
  return {
    flatIndex: -1,
    parentPath: sourcePath
  }
}

export const msToTime = (milliseconds: number, concise: boolean = false): string => {
  const secConst = 1000
  const minConst = secConst * 60
  const hourConst = minConst * 60
  const dayConst = hourConst * 24;
  let msReminder = milliseconds % dayConst
  milliseconds = milliseconds - msReminder
  const days = milliseconds / dayConst
  milliseconds = msReminder
  msReminder = milliseconds % hourConst
  milliseconds = milliseconds - msReminder
  const hrs = milliseconds / hourConst
  milliseconds = msReminder
  msReminder = milliseconds % minConst
  milliseconds = milliseconds - msReminder
  const mins = milliseconds / minConst
  milliseconds = msReminder
  msReminder = milliseconds % secConst
  milliseconds = milliseconds - msReminder
  const secs = milliseconds / secConst
  if (concise) {
    if (days > 0) {
      return days + 'd ' + hrs + 'h'
    }
    if (hrs > 0) {
      return hrs + 'h ' + mins + 'm'
    }
    if (mins > 0) {
      return mins + 'm ' + secs + 's'
    }
    if (secs > 0) {
      return secs + 's'
    }
    return 'n/a'
  }
  if (days > 0) {
    return days + ' days ' + hrs + ' hrs ' + mins + ' min'
  }
  if (hrs > 0) {
    return hrs + ' hrs ' + mins + ' min ' + secs + ' sec'
  }
  if (mins > 0) {
    return mins + ' min ' + secs + ' sec'
  }
  if (secs > 0) {
    return secs + ' sec'
  }
  return 'n/a';
}

export const unsubscribeSafe = (unsubscribe: any) => {
  if (typeof unsubscribe === 'function') {
    unsubscribe()
    return
  }
  if (Array.isArray(unsubscribe)) {
    unsubscribe.forEach((item) => {
      if (typeof item === 'function') {
        item()
      }
    })
  }
}

String.prototype.wrapLinks = function(this: string): string {
  const urlPattern = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/ig
  return this.replace(urlPattern, (url): string => {
    const protocolPattern = /^(?:(?:https?|ftp):\/\/)/i;
    const href = protocolPattern.test(url) ? url : 'http://' + url;
    const target = /getpigeon\.com\//.test(href) ? '_self' : '_blank';
    return '<a class="link" href="' + href + '" target="' + target + '" style="color: white;">' + url + '</a>';
  });
};

export const getAbsoluteBoundingRect = (el) => {
  const doc = document
  const body = doc.body
  const rect = el.getBoundingClientRect()
  const win = window
  let offsetX = win.pageXOffset !== undefined ? win.pageXOffset :
    (doc.documentElement || body.parentNode || body).scrollLeft
  let offsetY = win.pageYOffset !== undefined ? win.pageYOffset :
    (doc.documentElement || body.parentNode || body).scrollTop
  if (el !== body) {
    let parent = el.parentNode;
    while (parent !== body) {
      offsetX += parent.scrollLeft;
      if (parent.scrollTop > 0 && !parent.classList.contains('overflow-y-auto')) {
        offsetY += parent.scrollTop;
      }
      parent = parent.parentNode;
    }
  }

  return {
    bottom: rect.bottom + offsetY,
    height: rect.height,
    left: rect.left + offsetX,
    right: rect.right + offsetX,
    top: rect.top + offsetY,
    width: rect.width
  };
}

export const hashCode = (str: string): number => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash += Math.pow(str.charCodeAt(i) * 31, str.length - i);
    // tslint:disable-next-line:no-bitwise
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
}

export const lineBreaks = (text) => {
  if (!text || !text.length) {
    return ''
  }
  return text.replace(/(?:\r\n|\r|\n)/g, '<br>')
}

export const getMobileOS = () => {
  const userAgent = navigator.userAgent;
  
  // android
  if(/android/i.test(userAgent)){
      return 'android';
  }

  //ios
  if(/iPad|iPhone|iPod/i.test(userAgent)){
      return 'ios';
  }

  return 'web';
}

export const phoneFormat = (phoneNumber) => {
  
  if (!phoneNumber) {
    return '';
  }

  try{
    let phone = phoneNumber;
    phone = phone.replace(/\D+/g, '');
    
    if(phone.length === 10 || phone.length === 11){
      if(phone.length === 10){
        phone = "1" + phone;
      }
      phoneNumber = phone.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '+$1 $2 $3 $4');
    }
  } catch (err) {
    console.error('Error in phoneFormat', err);
  } 

  return phoneNumber;  
}

export const getFirstName = (userName) => {  
  if (!userName) {
    return '';
  }

  try{   
    const nameArr = userName.split(' ')
    userName = nameArr[0]    
  } catch (err) {
    console.error('Error in getFirstName', err);
  }   
  return userName;  
}

export const resizeImage = (settings: IResizeImageOptions) => {
  const file = settings.file;
  const maxSize = settings.maxSize;
  const reader = new FileReader();
  const image = new Image();
  const canvas = document.createElement('canvas');

  const dataURItoBlob = (dataURI: string) => {
    const bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ? atob(dataURI.split(',')[1]) : unescape(dataURI.split(',')[1]);
    const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const max = bytes.length;
    const ia = new Uint8Array(max);
    for (var i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i);
    return new Blob([ia], {type:mime});
  };

  const resize = () => {
    let width = image.width;
    let height = image.height;

    if (width > height) {
        if (width > maxSize) {
            height *= maxSize / width;
            width = maxSize;
        }
    } else {
        if (height > maxSize) {
            width *= maxSize / height;
            height = maxSize;
        }
    }
    canvas.width = width;
    canvas.height = height;
    canvas.getContext('2d')?.drawImage(image, 0, 0, width, height);    
    
    let dataUrl = canvas.toDataURL(file.type);
    return dataURItoBlob(dataUrl);
  };

  return new Promise((ok, no) => {
      if (!file.type.match(/image.*/)) {
        no(new Error("Not an image"));
        return;
      }
      reader.onload = (readerEvent: any) => {
        image.onload = () => ok(resize());
        image.src = readerEvent.target.result;
      };
      reader.readAsDataURL(file);
  })

}


export const getCustomImageUrl = async (textSessionId, file) => {

  let imageUrl = '';  

  try{

    const fileName = file.name.replace(' ','');
    imageUrl = `mms/${textSessionId}/${Date.now()}/${fileName}`;    
    const validImageTypes = ['image/jpg', 'image/jpeg', 'image/png'];

    if(validImageTypes.includes(file.type) && (file.size > constants.MMS_MAX_FILE_SIZE)) {
      // crop
      const config = {
        file: file,
        maxSize: constants.MMS_MAX_FILE_WIDTH
      };

      const resizedImage = await resizeImage(config);
      await uploadBytes(ref(storage, '/' + imageUrl), resizedImage as unknown as Blob);

    } else if (!validImageTypes.includes(file.type) && (file.size > constants.MMS_MAX_FILE_SIZE)){      
      // large file
      alert('File size is too large. Required file size is under 700kb.')
      imageUrl = '';

    }else{
      // no crop
      await uploadBytes(ref(storage, '/' + imageUrl), file); 
    }
    
  } catch (err) {
    console.error('Error in getCustomImageUrl')  
    console.error(err)
  }

  return imageUrl;
}

export async function sendEmail(userId: string, token: string, to: string, bcc: string, subject: string, text: string, html: string, isArchive: boolean) {

  if (!to) {
    return false;
  }
 
  try{
    
    axios.defaults.timeout = 9 * 60 * 1000;

    const result = await axios.get(env.VUE_APP_API_FUNCTION_URL + '/apiSendEmail', {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: {
        userId: userId,
        to: to,
        bcc: bcc,   
        subject: subject,
        text: text,
        html: html,
        isArchive: isArchive
      }
    });
  
    // result
    let responseBody = {};
  
    if (result.status === 200) {  
      if(result.data.status === 200 && result.data.errorCode === '0'){
        console.log('Success')
        responseBody = {
          errorCode: '0'
        };
        return true;
      }else{
        console.error('Success with error code')
        responseBody = {
          errorCode: result.data.errorCode,
          errorText: result.data.errorText
        }; 
        return false;
      }        
    }

  } catch (err) {   
    console.error('Error in Send Email')  
    console.error(err)
  } 

  axios.defaults.timeout = 60 * 1000;

  // reposne
  return false;
}