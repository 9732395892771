import {collection, CollectionReference, doc, DocumentReference} from 'firebase/firestore';
import {firestore as db} from '@/plugins/firebase.init';
import type {Functions} from "@firebase/functions";

export let functions: Functions
export const businesses = collection(db, 'businesses')
export const chats = collection(db, 'textSessions')
export const users = collection(db, 'users')
export const fcmTokens = collection(db, 'fcmTokens')
export const verifications = collection(db, 'verifications')
export const businessCategories = collection(db, 'businessCategories')
export const systemUpdates = collection(db, 'systemUpdates')
export const appVersionDoc = doc(systemUpdates, 'application_version')
export const smsBusinesses = collection(db, 'smsBusiness')
export const mails = collection(db, 'mails')

export const user = (id: string) => doc(users, id)
export const userAssistants = (id: string) => collection(user(id), 'assistants')
export const userAssistant = (userId: string, assistantId: string) => doc(userAssistants(userId), assistantId)
export const userBookedDates = (id: string) => collection(user(id), 'bookedDates')
export const userBookedDate = (userId: string, bookId: string) => doc(userBookedDates(userId), bookId)
export const userAppointDays = (id: string) => collection(user(id), 'appointDays')
export const userAppointDay = (userId: string, dayId: string) => doc(userAppointDays(userId), dayId)
export const userAppointDayByRef = (col: CollectionReference, dayId: string) => doc(col, dayId)
export const business = (id: string) => doc(businesses, id)
export const businessInvites = (businessId: string) => collection(business(businessId), 'invites')
export const businessInvite = (businessId: string, inviteId: string) => doc(businessInvites(businessId), inviteId)
export const businessChannels = (businessId: string) => collection(business(businessId), 'channels')
export const businessChannel = (businessId: string, channelId: string) => doc(businessChannels(businessId), channelId)
export const businessChannelMessages = (businessId: string, channelId: string) => collection(businessChannel(businessId, channelId), 'channelMessages')
export const businessChannelMessage = (businessId: string, channelId: string, messageId: string) => doc(businessChannelMessages(businessId, channelId), messageId)
export const businessChannelMembers = (businessId: string, channelId: string) => collection(businessChannel(businessId, channelId), 'members')
export const businessChannelMember = (businessId: string, channelId: string, memberId: string) => doc(businessChannelMembers(businessId, channelId), memberId)
export const businessAssociates = (businessId: string) => collection(business(businessId), 'associates')
export const businessAssociate = (businessId: string, associateId: string) => doc(businessAssociates(businessId), associateId)
export const businessCustomers = (businessId: string) => collection(business(businessId), 'businessCustomers')
export const businessCustomer = (businessId: string, customerId: string) => doc(businessCustomers(businessId), customerId)
export const businessCustomerNotes = (businessId: string, customerId: string) => collection(businessCustomer(businessId, customerId), 'notes')
export const businessCustomerNote = (businessId: string, customerId: string, noteId: string) => doc(businessCustomerNotes(businessId, customerId), noteId)
export const businessCustomerNoteReplies = (businessId: string, customerId: string, noteId: string) => collection(businessCustomerNote(businessId, customerId, noteId), 'replies')
export const businessCustomerNoteReply = (businessId: string, customerId: string, noteId: string, replyId: string) =>
  doc(businessCustomerNoteReplies(businessId, customerId, noteId), replyId)
export const businessDirectory = (businessId: string) => collection(business(businessId), 'directory')
export const businessDirectoryContact = (businessId: string, contactId: string) => doc(businessDirectory(businessId), contactId)
export const businessDirectoryContactBacking = (businessId: string, contactId: string) => collection(businessDirectoryContact(businessId, contactId), 'backingContacts')
export const businessDirectoryContactBackingContact = (businessId: string, contactId: string, backingId: string) =>
  doc(businessDirectoryContactBacking(businessId, contactId), backingId)
export const businessAppointments = (businessId: string) => collection(business(businessId), 'appointments')
export const businessAppointment = (businessId: string, appointId: string) => doc(businessAppointments(businessId), appointId)
export const businessCases = (businessId: string) => collection(business(businessId), 'cases')
export const businessCasesArchive = (businessId: string) => collection(business(businessId), 'archive')
export const businessCaseArchive = (businessId: string, archiveId: string) => doc(businessCasesArchive(businessId), archiveId)
export const businessCase = (businessId: string, caseId: string) => doc(businessCases(businessId), caseId)
export const businessCaseNotes = (businessId: string, caseId: string) => collection(businessCase(businessId, caseId), 'notes')
export const businessCaseNote = (businessId: string, caseId: string, noteId: string) => doc(businessCaseNotes(businessId, caseId), noteId)
export const businessCaseNoteReplies = (businessId: string, caseId: string, noteId: string) => collection(businessCaseNote(businessId, caseId, noteId), 'replies')
export const businessCaseNoteReply = (businessId: string, caseId: string, noteId: string, replyId: string) =>
  doc(businessCaseNoteReplies(businessId, caseId, noteId), replyId)
export const businessCustomersStats = (businessId: string) => collection(business(businessId), 'customersStats')
export const businessRatingStats = (businessId: string) => collection(business(businessId), 'ratingStats')
export const businessCasesStats = (businessId: string) => collection(business(businessId), 'casesStats')
export const businessResponseTimeStats = (businessId: string) => collection(business(businessId), 'responseTimeStats')
export const businessInteractionsStats = (businessId: string) => collection(business(businessId), 'interactionsStats')
export const businessCasesTypesStats = (businessId: string) => collection(business(businessId), 'casesTypesStats')
export const businessStaffPerformance = (businessId: string) => collection(business(businessId), 'staffPerformance')
export const chat = (id: string) => doc(chats, id)
export const chatMessages = (id: string) => collection(chat(id), 'messages')
export const chatMessage = (chatId: string, messageId: string) => doc(chatMessages(chatId), messageId)
export const newChatMessage = (chatId: string) => doc(chatMessages(chatId))
export const fcmToken = (id: string) => doc(fcmTokens, id)
export const mail = (id: string) => doc(mails, id)
export const smsBusiness = (businessId: string) => doc(smsBusinesses, businessId)
export const verification = (id: string) => doc(verifications, id)
export const releaseNotes = collection(appVersionDoc, 'releaseNotes')
export const userReports = collection(db, 'userReports')
export const setup = collection(db, 'setup')
export const businessSetup = (businessId: string) => doc(setup, businessId)
export const settings = collection(db, 'settings')
export const businessSettings = (businessId: string) => doc(settings, businessId)

export let userBusiness: (userId: string, businessId: string) => DocumentReference
