import {MutationTree} from 'vuex';
import {AuthState} from '@/domain/model/types';

export const mutations: MutationTree<AuthState> = {
  saveOobCode(state, oobCode: string) {
    state.oobCode = oobCode
  },
  resetOobCode(state) {
    state.oobCode = null
  },
  companiesLoading(state) {
    state.companiesLoading = true;
    state.companiesError = '';
  },
  companiesLoaded(state, payload: any[]) {
    state.companies = payload;
    state.companiesError = '';
    state.companiesLoading = false;
  },
  companiesError(state, error: string) {
    state.companies = [];
    state.companiesError = error;
    state.companiesLoading = false;
  },
  detailsLoading(state) {
    state.detailsLoading = true;
    state.detailsError = '';
  },
  detailsLoaded(state, payload: any) {
    state.companyDetails = payload;
    state.detailsError = '';
    state.detailsLoading = false;
  },
  detailsError(state, error: string) {
    state.companyDetails = null;
    state.detailsError = error;
    state.detailsLoading = false;
  },
  getStartedLoading(state) {
    state.loading = true;
    state.error = '';
  },
  getStartedLoaded(state) {
    state.loading = false;
    state.error = '';
  },
  getStartedError(state, error: string) {
    state.loading = false;
    state.error = error;
  },
  verificationLoading(state) {
    state.loading = true;
    state.error = '';
  },
  verificationLoaded(state, email) {
    state.loading = false;
    state.error = '';
  },
  verificationError(state, error: string) {
    state.loading = false;
    state.error = error;
  },
  loading(state) {
    state.loading = true;
    state.error = '';
  },
  loaded(state) {
    state.loading = false;
    state.error = '';
  },
  error(state, error: string) {
    state.loading = false;
    state.error = error;
  }
};
