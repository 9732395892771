import {ActionCodeSettings} from 'firebase/auth';
import {Timestamp} from 'firebase/firestore';

const pjson = require('../../package.json');
const env: any = process.env;

const config = {
  messagingOptions: {
    vapidKey: env.VUE_APP_VAPID_KEY
  },
  appConfig: {
    apiKey: env.VUE_APP_WEB_API_KEY,
    authDomain: env.VUE_APP_AUTH_DOMAIN,
    databaseURL: env.VUE_APP_DB_URL,
    projectId: env.VUE_APP_PROJECT_ID,
    storageBucket: env.VUE_APP_STORAGE_BUCKET,
    messagingSenderId: env.VUE_APP_MESSAGING_SENDER_ID,
    appId: env.VUE_APP_APP_ID,
    measurementId: env.VUE_APP_MEASUREMENT_ID
  },
  dlConfig: {
    apn: env.VUE_APP_DL_APN,
    bid: env.VUE_APP_DL_BID,
    hostUrl: env.VUE_APP_DL_HOST_URL,
    domain: env.VUE_APP_DL_DOMAIN,
    authDomain: env.VUE_APP_DL_DOMAIN_AUTH,
    actionCodeSettings: {
      android: {
        installApp: !!env.VUE_APP_DL_ACS_ANDROID_INSTALL_APP,
        packageName: env.VUE_APP_DL_ACS_ANDROID_PN
      },
      handleCodeInApp: !!env.VUE_APP_DL_ACS_HANDLE_CODE_IN_APP,
      iOS: {
        bundleId: env.VUE_APP_DL_ACS_IOS_BID,
        appStoreId: env.VUE_APP_DL_ACS_IOS_ASID
      },
      url: env.VUE_APP_DL_ACS_URL,
      dynamicLinkDomain: env.VUE_APP_DL_ACS_DL_DOMAIN
    } as ActionCodeSettings,
    authActionCodeSettings: {
      android: {
        installApp: !!env.VUE_APP_DL_ACS_ANDROID_INSTALL_APP,
        packageName: env.VUE_APP_DL_ACS_ANDROID_PN
      },
      handleCodeInApp: !!env.VUE_APP_DL_ACS_HANDLE_CODE_IN_APP,
      iOS: {
        bundleId: env.VUE_APP_DL_ACS_IOS_BID,
        appStoreId: env.VUE_APP_DL_ACS_IOS_ASID
      },
      url: env.VUE_APP_DL_ACS_URL,
      dynamicLinkDomain: env.VUE_APP_DL_ACS_DL_DOMAIN_AUTH
    } as ActionCodeSettings
  },
  dlConfigCustomer: {
    apn: env.VUE_APP_DL_APN_CUSTOMER,
    bid: env.VUE_APP_DL_BID_CUSTOMER,
    hostUrl: env.VUE_APP_DL_HOST_URL,
    domain: env.VUE_APP_DL_DOMAIN,
    authDomain: env.VUE_APP_DL_DOMAIN_AUTH,
    actionCodeSettings: {
      android: {
        installApp: !!env.VUE_APP_DL_ACS_ANDROID_INSTALL_APP,
        packageName: env.VUE_APP_DL_APN_CUSTOMER
      },
      handleCodeInApp: !!env.VUE_APP_DL_ACS_HANDLE_CODE_IN_APP,
      iOS: {
        bundleId: env.VUE_APP_DL_BID_CUSTOMER,
        appStoreId: env.VUE_APP_DL_ACS_IOS_ASID_CUSTOMER
      },
      url: env.VUE_APP_DL_ACS_URL,
      dynamicLinkDomain: env.VUE_APP_DL_ACS_DL_DOMAIN
    } as ActionCodeSettings,
    authActionCodeSettings: {
      android: {
        installApp: !!env.VUE_APP_DL_ACS_ANDROID_INSTALL_APP,
        packageName: env.VUE_APP_DL_APN_CUSTOMER
      },
      handleCodeInApp: !!env.VUE_APP_DL_ACS_HANDLE_CODE_IN_APP,
      iOS: {
        bundleId: env.VUE_APP_DL_BID_CUSTOMER,
        appStoreId: env.VUE_APP_DL_ACS_IOS_ASID_CUSTOMER
      },
      url: env.VUE_APP_DL_ACS_URL,
      dynamicLinkDomain: env.VUE_APP_DL_ACS_DL_DOMAIN_AUTH
    } as ActionCodeSettings
  },
  mapsApiKey: env.VUE_APP_MAPS_API_KEY,
  appDomain: env.VUE_APP_DOMAIN,
  buildVersion: 'v' + pjson.version + '(' + pjson.fileVersion + ')',
  buildDate: new Date(parseInt(pjson.buildDate + '000', 10)).toLocaleDateString()
};

const appConfig = config.appConfig;
const dynamicLinkConfig = config.dlConfig;
const dynamicLinkConfigCustomer = config.dlConfigCustomer;
const mapsApiKey = config.mapsApiKey;
const appDomain = config.appDomain;
const buildVersion = config.buildVersion;
const buildDate = config.buildDate;
const messagingOptions = config.messagingOptions

export {
  appConfig,
  dynamicLinkConfig,
  dynamicLinkConfigCustomer,
  mapsApiKey,
  appDomain,
  buildVersion,
  buildDate,
  messagingOptions
};
