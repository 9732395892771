import {DependencyContainer, FactoryProvider, injectable, singleton} from 'tsyringe';
import {EditChannelUseCase, editChannelUseCase} from '@/domain/editChannelUseCase';
import {uploadChannelImageUseCase, UploadChannelImageUseCase} from '@/domain/uploadChannelImageUseCase';

@injectable()
@singleton()
export default class UploadChannelImageUseCaseFactory implements FactoryProvider<UploadChannelImageUseCase> {
  public useFactory(dc: DependencyContainer): UploadChannelImageUseCase {
    return uploadChannelImageUseCase(dc.resolve('ChannelsImagesRepository'));
  }
}
