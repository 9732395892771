import {Associate} from '@/domain/model/associate';
import {DocumentData} from 'firebase/firestore'

export class AssociateMapper {
  //todo: consider pass in DocumentSnapshot
  public static map(doc: DocumentData): Associate {
    const business = {name: doc.business.name, id: doc.business.id};
    return new Associate(doc.fullName, doc.email, doc.position, doc.phoneNumber,
      business, doc.type, doc.admin, doc.superAdmin, doc.roles, doc.ssn, doc.photoUrl,
      doc.workingStatus, doc.status, doc.defaultContactId, doc.header, doc.inbox, doc.stats,
      doc.disabled, doc.hidden, doc.invited, doc.invitedAt);
  }
}
