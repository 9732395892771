import {ActionTree} from 'vuex';
import {HomeState} from '@/store/modules/home/index';
import {RootState} from '@/domain/model/types';
import {firestore} from '@/plugins/firebase.init';
import {arrayRemove, arrayUnion, onSnapshot, Timestamp, updateDoc, writeBatch} from 'firebase/firestore';
import {business, businessSetup} from '@/data/firebase';

export const actions: ActionTree<HomeState, RootState> = {
  loadSetup: ({commit, rootGetters}) => {
    console.log('loading setup...');
    const businessId = rootGetters.business && rootGetters.business.id;
    if (!businessId) {
      console.log('no business id');
      return null;
    }
    return onSnapshot(businessSetup(businessId), (snapshot) => {
      if (snapshot.exists()) {
        const data: any = snapshot.data();
        console.log('setup data = ' + data);
        commit('setFulfilled', data.fulfilled);
        commit('setTodo', data.todo);
        commit('setStep', data.todo[0]);
      }
    });
  },
  completeStep: async ({commit, rootGetters}, tag) => {
    const businessId = rootGetters.business && rootGetters.business.id;
    if (!businessId) {
      console.log('no business id');
      return false;
    }
    await updateDoc(businessSetup(businessId), {
      todo: arrayRemove(tag),
      fulfilled: arrayUnion(tag)
    });
    return true;
  },
  saveCompanyInfo: async ({commit, rootGetters}, {tag, payload}) => {
    const businessId = rootGetters.business && rootGetters.business.id;
    const userId = rootGetters.t2bUser && rootGetters.t2bUser.id;
    if (!businessId || !userId) {
      console.log('no user or business undefined');
      return false;
    }
    try {
      commit('setBusy');
      await writeBatch(firestore)
        .update(business(businessId), {...payload.payload, createdDate: Timestamp.now()})
        .update(businessSetup(businessId), {
          todo: arrayRemove(tag),
          fulfilled: arrayUnion(tag)
        })
        .commit();
      commit('setRelease');
      return true;
    } catch (e: any) {
      commit('setError', e.message);
    }
    return false;
  },
  clearSetupState({commit}) {
    commit('resetSetupState');
    return true;
  }
};
